import React from "react";
import homeLogo from "../../assets/img/home_logo.png";
import { splashlogo,main_logo,splash } from "../../assets/media";

const SplashScreen = () => {
  return (
    <>
       <div className="splash-container">
        <img src={splashlogo} alt="" className="img1"/>
        <h4 style={{ color: "#fff", marginTop: "22px" , fontSize: "1.4rem"}} >Withdraw fast, safe and stable</h4>
        <img src={homeLogo} alt="" className="img2" />
      </div>
    </>
  );
};

export default SplashScreen;
