import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import BottomNav from "../common/BottomNav";
import CustomHeader from "../common/CustomHeader";
import "../../assets/css/promotion.css";
import GradientButton from "../components/GradientButton";
import Toast from "../components/Toast";
import { formatMoney, showLoader } from "../../utils/Utils";
import { getPromotion, getUser } from "../../utils/UserController";
import PartnerImg from "../../assets/img/parners.svg"

import copyImg from "../../assets/img/svgexport-6.svg"
import subordinateImg from "../../assets/img/subordinate.svg"
import commissionImg from "../../assets/img/svgexport-10.svg"

import inviteImg from "../../assets/img/svgexport-11.svg"

import AgentImg from "../../assets/img/svgexport-12.svg"
import RebateImg from "../../assets/img/svgexport-13.svg"
import subImg from "../../assets/img/svgexport-14.svg"


const Promotion = () => {
  const [toast, setToast] = useState({
    isVisible: false,
    message: "",
  });

  const [user, setUser] = useState({});
  const [promotion, setPromotion] = useState([]);
  const navigate = useNavigate();

  const showToast = (message) => {
    setToast({ isVisible: true, message });
    setTimeout(() => {
      setToast({ isVisible: false, message: "" });
    }, 3000); // Hide after 3 seconds
  };
  const handleInvite = () => {
    navigate("/promotion/share", {
      state: user?.join_code,
    });
  };

  const handleCopy = (txt) => {
    navigator.clipboard
      .writeText(txt)
      .then(() => {
        showToast(
          `<svg width="25px" height="25px" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--emojione-monotone" preserveAspectRatio="xMidYMid meet" fill="#fff" stroke="#fff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M56 2L18.8 42.909L8 34.729H2L18.8 62L62 2z" fill="#fff"></path></g></svg>Copy successful`
        );
      })
      .catch((err) => {
        showToast("Failed to copy");
      });
  };
  useEffect(() => {
    const getUserData = async () => {
      showLoader(true);
      try {
        const res = await getUser();
        setUser(res.data.data);
        showLoader(false);
      } catch (error) {
        console.log(error);
      }
    };
    const getPromorationData = async () => {
      showLoader(true);
      try {
        const res = await getPromotion();
        setPromotion(res.data.data);
        showLoader(false);
      } catch (error) {
        console.log(error);
      }
    };
    getUserData();

    getPromorationData();
  }, []);

  return (
    <>
      <CustomHeader
        bg={"var(-themeBlack)"}
        color={"var(--mainText)"}
        heading={"Agency"}
        tops="tops"
        
      />
   
      <div className="promotion-container">
        <span className="ytc-amt">0</span>
        <span className="ytc-text">Yesterday's total commission</span>
        <span className="tip">
          Upgrade the level to increase commission income
        </span>
        <div className="promo-table-container">
          <div className="table">
            <div className="table-header">
              <div className="table-row">
                <div className="table-col">Direct subordinates</div>
                <div className="border-s"></div>
                <div className="table-col">Team subordinates</div>
              </div>
            </div>
            <div className="table-body">
              <div className="table-row">
                <div className="table-col">
                  <div className="d-flex flex-col">
                    <span className="sub-num">{promotion?.users}</span>
                    <span className="sub-txt">number of register</span>
                  </div>
                </div>
                <div className="table-col">
                  <div className="d-flex flex-col">
                    <span className="sub-num">0</span>
                    <span className="sub-txt">number of register</span>
                  </div>
                </div>
              </div>
              <div className="table-row">
                <div className="table-col">
                  <div className="d-flex flex-col">
                    <span className="sub-num text-green">
                      {promotion?.deposits}
                    </span>
                    <span className="sub-txt">Deposit number</span>
                  </div>
                </div>
                <div className="table-col">
                  <div className="d-flex flex-col">
                    <span className="sub-num text-green">0</span>
                    <span className="sub-txt">Deposit number</span>
                  </div>
                </div>
              </div>
              <div className="table-row">
                <div className="table-col">
                  <div className="d-flex flex-col">
                    <span className="sub-num text-yellow">
                      {promotion?.total_deposits}
                    </span>
                    <span className="sub-txt">Deposit amount</span>
                  </div>
                </div>
                <div className="table-col">
                  <div className="d-flex flex-col">
                    <span className="sub-num text-yellow">0</span>
                    <span className="sub-txt">Deposit amount</span>
                  </div>
                </div>
              </div>
              <div className="table-row">
                <div className="table-col">
                  <div className="d-flex flex-col">
                    <span className="sub-num">0</span>
                    <span className="sub-txt">
                      {" "}
                      Number of people making first deposit
                    </span>
                  </div>
                </div>
                <div className="table-col">
                  <div className="d-flex flex-col">
                    <span className="sub-num">0</span>
                    <span className="sub-txt">
                      {" "}
                      Number of people making first deposit
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="invite-link-btn" onClick={handleInvite}>
          <GradientButton
            text={"INVITATION LINK"}
            width={"90%"}
            textsize="1rem"
            fw={"bold"}
            bg={"var(--main_gradient-color)"}
            color={"#fff"}
          />
        </div>
        
        
        <div className="sub-link-container">
        
         <Link >
            <div className="sub-link">
              <div className="d-flex align-center" style={{ gap: "5px" }}>
             <img src={PartnerImg} style={{width:"31px"}}/>
             
             <span className="sub-link-txt">Partner rewards</span>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25px"
                height="25px"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#1e2637"
                  d="m8.006 21.308l-1.064-1.064L15.187 12L6.942 3.756l1.064-1.064L17.314 12z"
                />
              </svg>
            </div>
          </Link>
        
          <div className="sub-link">
            <div className="d-flex align-center" style={{ gap: "5px" }}>
               <img src={copyImg} style={{width:"31px"}}/>
              
              <span className="sub-link-txt">Copy invitation code</span>
            </div>
            <div
              className="d-flex align-center"
              style={{ gap: "5px", color: "#768096",fontSize:"12px", }}
              onClick={() => handleCopy(user?.join_code)}
            >
              <span>{user?.join_code}</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20px"
                height="20px"
                viewBox="0 0 256 256"
              >
                <path
                  fill="#768096"
                  d="M216 32H88a8 8 0 0 0-8 8v40H40a8 8 0 0 0-8 8v128a8 8 0 0 0 8 8h128a8 8 0 0 0 8-8v-40h40a8 8 0 0 0 8-8V40a8 8 0 0 0-8-8m-56 176H48V96h112Zm48-48h-32V88a8 8 0 0 0-8-8H96V48h112Z"
                />
              </svg>
            </div>
          </div>
          <Link to={"/promotion/SubordinateData"}>
            <div className="sub-link">
              <div className="d-flex align-center" style={{ gap: "5px" }}>
                       <img src={subordinateImg} style={{width:"31px"}}/>
                    <span className="sub-link-txt">Subordinate data</span>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25px"
                height="25px"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#1e2637"
                  d="m8.006 21.308l-1.064-1.064L15.187 12L6.942 3.756l1.064-1.064L17.314 12z"
                />
              </svg>
            </div>
          </Link>
          <Link to={"/promotion/CommissionDetails"}>
            <div className="sub-link">
              <div className="d-flex align-center" style={{ gap: "5px" }}>
                 <img src={commissionImg} style={{width:"31px"}}/>
                 
                <span className="sub-link-txt">Commission detail</span>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25px"
                height="25px"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#1e2637"
                  d="m8.006 21.308l-1.064-1.064L15.187 12L6.942 3.756l1.064-1.064L17.314 12z"
                />
              </svg>
            </div>
          </Link>
          <Link to={"/promotion/PromotionRule"}>
            <div className="sub-link">
              <div className="d-flex align-center" style={{ gap: "5px" }}>
                    <img src={inviteImg} style={{width:"31px"}}/>
                <span className="sub-link-txt">Invitation rules</span>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25px"
                height="25px"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#1e2637"
                  d="m8.006 21.308l-1.064-1.064L15.187 12L6.942 3.756l1.064-1.064L17.314 12z"
                />
              </svg>
            </div>
          </Link>
          <Link to={"/promotion/CustomerSupport"}>
            <div className="sub-link">
              <div className="d-flex align-center" style={{ gap: "5px" }}>
                  <img src={AgentImg} style={{width:"31px"}}/>
                <span className="sub-link-txt">
                  Agent line customer service
                </span>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25px"
                height="25px"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#1e2637"
                  d="m8.006 21.308l-1.064-1.064L15.187 12L6.942 3.756l1.064-1.064L17.314 12z"
                />
              </svg>
            </div>
          </Link>
          <Link to={"/promotion/RebateRatio"}>
            <div className="sub-link">
              <div className="d-flex align-center" style={{ gap: "5px" }}>
            
                <img src={RebateImg} style={{width:"31px"}}/>
                <span className="sub-link-txt">Rebate ratio</span>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25px"
                height="25px"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#1e2637"
                  d="m8.006 21.308l-1.064-1.064L15.187 12L6.942 3.756l1.064-1.064L17.314 12z"
                />
              </svg>
            </div>
          </Link>
          <div
            className="gift-history-container"
            style={{ width: "100%", height: "auto" }}
          >
            <div className="gift-history-header">
               <img src={subImg} style={{width:"31px"}}/>
              <span>promotion data</span>
            </div>

            <div className="promotion-data-container">
              <div className="promotion-data">
                <span className="sub-num">{promotion?.users}</span>
                <span className="sub-txt">This Week</span>
              </div>
              <div className="promotion-data">
                <span className="sub-num">
                  {promotion?.total_comm?promotion?.total_comm:0}
                </span>
                <span className="sub-txt">Total commission</span>
              </div>
              <div className="promotion-data">
                <span className="sub-num">{promotion?.users}</span>
                <span className="sub-txt">direct subordinate</span>
              </div>
              <div className="promotion-data">
                <span className="sub-num">0</span>
                <span className="sub-txt">
                  Total number of subordinates in the team
                </span>
              </div>
            </div>
          </div>
          <div className="size-box-50"></div>
          <div className="size-box-50"></div>
        </div>
      </div>
      <BottomNav />
      <Toast
        message={toast.message}
        isVisible={toast.isVisible}
        onClose={() => setToast({ isVisible: false, message: "" })}
      />
    </>
  );
};

export default Promotion;
