import React from "react";
import { Route, Routes } from "react-router-dom";

import Home from "../pages/Home";
import Account from "../pages/Account";
import Promotion from "../pages/Promotion";
import Wallet from "../pages/Wallet";
import Activity from "../pages/Activity";
import Comming from "../pages/Comming";
import Msg from "../components/home/Msg";
import Notification from "../pages/Notification";
import Games from "../components/home/Games";
import Wingo from "../components/gamepage/Wingo";
import LoginPage from "../pages/LoginPage";
import { ProtectedRoute } from "../../utils/ProtectedRoute";
import K3 from "../components/gamepage/K3";
import TrxWin from "../components/gamepage/TrxWin";
import RegisterPage from "../pages/RegisterPage";
import D5 from "../components/gamepage/D5";
import InvitationBonus from "../components/activity/InvitationBonus";
import BettingRebet from "../components/activity/BettingRebet";
import RebetHistory from "../components/activity/RebetHistory";
import SuperJackpot from "../components/activity/SuperJackpot";
import RedeemGift from "../components/activity/RedeemGift";
import Attendance from "../components/activity/Attendance";
import GameRule from "../components/activity/GameRule";
import AttendanceHistory from "../components/activity/AttendanceHistory";
import SubordinateData from "../components/promotion/SubordinateData";
import CommissionDetails from "../components/promotion/CommissionDetails";
import PromotionRule from "../components/promotion/PromotionRule";
import RebateRatio from "../components/promotion/RebateRatio";
import CustomerSupport from "../components/promotion/CustomerSupport";
import Recharge from "../components/wallet/Recharge";
import RechargeHistory from "../components/wallet/RechargeHistory";
import Withdraw from "../components/wallet/Withdraw";
import WithdrawHistory from "../components/wallet/WithdrawHistory";
import AddBank from "../components/wallet/AddBank";
import AddUsdt from "../components/wallet/AddUsdt";
import BankAccount from "../components/wallet/BankAccount";
import { Vip } from "../components/account/Vip";
import FirstDeposit from "../components/activity/FirstDeposit";
import TransactionHistory from "../components/wallet/TransactionHistory";
import InvitationRules from "../components/activity/InvitationRules";
import InvitationRecord from "../components/activity/InvitationRecord";
import Guide from "../components/account/Guide";
import Setting from "../components/account/Setting";
import PasswordChange from "../components/account/PasswordChange";
import ReportIssue from "../components/ReportIssue";
import Xgame from "../components/Xgame";
import Notify from "../components/home/Notify";
import AvatarChange from "../components/account/AvatarChange";
import BetHistory from "../components/account/BetHistory";
import MyFeedback from "../components/account/MyFeedback";
import About from "../components/account/About";
import Invite from "../components/promotion/Invite";
import Risk from "../components/account/Risk";
import ConfidArg from "../components/account/ConfidArg";
import NewSubordinate from "../components/promotion/NewSubordinate";

const MyRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/comming" element={<ProtectedRoute element={Comming} />} />
      <Route path="/account" element={<ProtectedRoute element={Account} />} />
      <Route
        path="/promotion"
        element={<ProtectedRoute element={Promotion} />}
      />
      <Route path="/wallet" element={<ProtectedRoute element={Wallet} />} />
      <Route path="/activity" element={<ProtectedRoute element={Activity} />} />

      {/* Login */}
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<RegisterPage />} />

      {/* Home Routes */}
      <Route path="/home/Messages" element={<ProtectedRoute element={Msg} />} />
      <Route
        path="/home/AllGames"
        element={<ProtectedRoute element={Games} />}
      />

      {/* Games Routes */}
      <Route
        path="/home/AllLotteryGames/WinGo"
        element={<ProtectedRoute element={Wingo} />}
      />
      <Route
        path="/home/AllLotteryGames/K3"
        element={<ProtectedRoute element={K3} />}
      />
      <Route
        path="/home/AllLotteryGames/WinTrx"
        element={<ProtectedRoute element={TrxWin} />}
      />
      <Route
        path="/home/AllLotteryGames/5D"
        element={<ProtectedRoute element={D5} />}
      />
      <Route
        path="/home/Notify"
        element={<ProtectedRoute element={Notify} />}
      />

      {/* Xgame  */}
      <Route
        path="/home/Xgame/Trade"
        element={<ProtectedRoute element={Xgame} />}
      />

      {/* Activity */}
      <Route
        path="/activity/InvitationBonus"
        element={<ProtectedRoute element={InvitationBonus} />}
      />
      <Route
        path="/activity/BettingRebet"
        element={<ProtectedRoute element={BettingRebet} />}
      />
      <Route
        path="/activity/BettingRebet/AllHistory"
        element={<ProtectedRoute element={RebetHistory} />}
      />
      <Route
        path="/activity/SuperJackpot"
        element={<ProtectedRoute element={SuperJackpot} />}
      />
      <Route
        path="/activity/RedeemGift"
        element={<ProtectedRoute element={RedeemGift} />}
      />
      <Route
        path="/activity/Attendance"
        element={<ProtectedRoute element={Attendance} />}
      />
      <Route
        path="/activity/GameRules"
        element={<ProtectedRoute element={GameRule} />}
      />
      <Route
        path="/activity/InvitationRules"
        element={<ProtectedRoute element={InvitationRules} />}
      />
      <Route
        path="/activity/InvitationRecord"
        element={<ProtectedRoute element={InvitationRecord} />}
      />
      <Route
        path="/activity/AttendanceHistory"
        element={<ProtectedRoute element={AttendanceHistory} />}
      />
      <Route
        path="/activity/FirstDeposit"
        element={<ProtectedRoute element={FirstDeposit} />}
      />

      {/* Promotion */}

      <Route
        path="/promotion/SubordinateData"
        element={<ProtectedRoute element={SubordinateData} />}
      />
      <Route
        path="/promotion/CommissionDetails"
        element={<ProtectedRoute element={CommissionDetails} />}
      />
      <Route
        path="/promotion/PromotionRule"
        element={<ProtectedRoute element={PromotionRule} />}
      />
      <Route
        path="/promotion/RebateRatio"
        element={<ProtectedRoute element={RebateRatio} />}
      />
      <Route
        path="/promotion/CustomerSupport"
        element={ <CustomerSupport />}
      />
      <Route
        path="/promotion/share"
        element={<ProtectedRoute element={Invite} />}
      />
      <Route
        path="/promotion/Subordinate"
        element={<ProtectedRoute element={NewSubordinate} />}
      />

      {/* Wallet */}
      <Route
        path="/wallet/Recharge"
        element={<ProtectedRoute element={Recharge} />}
      />
      <Route
        path="/wallet/RechargeHistory"
        element={<ProtectedRoute element={RechargeHistory} />}
      />
      <Route
        path="/wallet/Withdraw"
        element={<ProtectedRoute element={Withdraw} />}
      />
      <Route
        path="/wallet/WithdrawHistory"
        element={<ProtectedRoute element={WithdrawHistory} />}
      />
      <Route
        path="/wallet/TransactionHistory"
        element={<ProtectedRoute element={TransactionHistory} />}
      />
      <Route
        path="/wallet/AddBank"
        element={<ProtectedRoute element={AddBank} />}
      />
      <Route
        path="/wallet/AddUsdt"
        element={<ProtectedRoute element={AddUsdt} />}
      />
      <Route
        path="/wallet/BankAccount"
        element={<ProtectedRoute element={BankAccount} />}
      />

      {/* Account */}
      <Route path="/vip" element={<ProtectedRoute element={Vip} />} />
      <Route path="/Guide" element={<ProtectedRoute element={Guide} />} />
      <Route path="/Setting" element={<ProtectedRoute element={Setting} />} />
      <Route
        path="/ChangePassword"
        element={<ProtectedRoute element={PasswordChange} />}
      />
      <Route
        path="/account/ChangeAvatar"
        element={<ProtectedRoute element={AvatarChange} />}
      />
      <Route
        path="/account/BetHistory"
        element={<ProtectedRoute element={BetHistory} />}
      />
      <Route
        path="/account/Feedback"
        element={<ProtectedRoute element={MyFeedback} />}
      />
      <Route
        path="/account/About"
        element={ <About />}
      />
      
      <Route
        path="/about/RiskDisclosureAgreement"
        element={ <Risk />}
      />
      <Route
        path="/about/ConfidentialityAgreement"
        element={<ConfidArg />}
      />
      <Route
        path="/SubmitIssue"
        element={<ProtectedRoute element={ReportIssue} />}
      />
      

      {/* Main Routes */}
      <Route
        path="/main/Notification"
        element={<ProtectedRoute element={Notification} />}
      />
    </Routes>
  );
};

export default MyRoutes;
