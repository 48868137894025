import axios from "../api/axiosConfig";

export const Deposit = async (payload) => {
  const token = localStorage.getItem("token");
  const uid = localStorage.getItem("uid");
  payload.uid = uid;
  try {
    const response = await axios.post("/api/user/deposit", payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Deposit error:", error);
  }
};

export const Checkout = async (payload) => {
  const token = localStorage.getItem("token");
  const uid = localStorage.getItem("uid");
  payload.uid = uid;
  try {
    const response = await axios.post("/api/user/checkout", payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Deposit error:", error);
  }
};

export const Widthdraw = async (payload) => {
  const token = localStorage.getItem("token");
  const uid = localStorage.getItem("uid");
  payload.uid = uid;
  try {
    const response = await axios.post("/api/user/withdraw", payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Deposit error:", error);
  }
};
