import React, { useState, useEffect } from "react";
import $ from "jquery";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LoginHeader from "../common/LoginHeader";
import { checkImg, checkCircle, eyeClose, eyeOpen } from "../../assets/media";
import { getOtp, Register } from "../../utils/UserController";
import Toast from "../components/Toast";
import { showLoader } from "../../utils/Utils";
import Phones2 from "../../assets/icons/phone2.svg"
import Phones from "../../assets/icons/phone.svg"
import Passwords from "../../assets/icons/password.svg"
import Invites from "../../assets/icons/invite.svg"
import En from "../../assets/icons/en.png"

const RegisterPage = () => {
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [inviteCode, setInviteCode] = useState("7050992100000");
  const [otp, setOtp] = useState("0000");
  const [registerTab, setRegisterTab] = useState("phone");
  const [viewPhonePass, setViewPhonePass] = useState(false);
  const [viewEmailPass, setViewEmailPass] = useState(false);
  const [viewPhoneCnfPass, setViewPhoneCnfPass] = useState(false);
  const [viewEmailCnfPass, setViewEmailCnfPass] = useState(false);
  const [agree, setAgree] = useState(false);
  const [sentOtp, setSentOtp] = useState(false);
  const [pcode, setPcode] = useState("+91");
  const [cphoneview, setCphoneview] = useState(false);
  const navigate = useNavigate();
  const [toast, setToast] = useState({
    isVisible: false,
    message: "",
  });
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paramValue = queryParams.get("InvitationCode"); // E
  const [openPopup, setOpenPopup] = useState(false)

  const showToast = (message) => {
    setToast({ isVisible: true, message });
    setTimeout(() => {
      setToast({ isVisible: false, message: "" });
    }, 3000);
  };

  const handleRegister = async () => {
    showLoader(true);
    if (registerTab === "email") {
      showToast(
        `<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="#fff" d="M13 2v14.5h-2V2zm-2 17h2.004v2.004H11z"></path></svg>Please register with your phone number`
      );
      showLoader(false);
      return false;
    }
    if (!agree) {
      showToast(
        `<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="#fff" d="M13 2v14.5h-2V2zm-2 17h2.004v2.004H11z"></path></svg>Please check I agree to the pre-sale rules`
      );
      showLoader(false);
      return;
    }

    if (phone === "" || phone.length < 10) {
      showToast(
        `<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="#fff" d="M13 2v14.5h-2V2zm-2 17h2.004v2.004H11z"></path></svg>Invalid phone`
      );
      showLoader(false);
      return false;
    }
    if (sentOtp) {
      showToast(
        `<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="#fff" d="M13 2v14.5h-2V2zm-2 17h2.004v2.004H11z"></path></svg>Send otp first!`
      );
      showLoader(false);
      return false;
    }
    if (otp === "" || otp.length < 4) {
      showToast(
        `<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="#fff" d="M13 2v14.5h-2V2zm-2 17h2.004v2.004H11z"></path></svg>Invalid otp`
      );
      showLoader(false);
      return false;
    }

    if (password === "" || password.length < 6) {
      showToast(
        `<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="#fff" d="M13 2v14.5h-2V2zm-2 17h2.004v2.004H11z"></path></svg>Enter password`
      );
      showLoader(false);
      return false;
    }

    if (password !== confirmPassword) {
      showToast(
        `<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="#fff" d="M13 2v14.5h-2V2zm-2 17h2.004v2.004H11z"></path></svg>Enter confirm password doesn't match.`
      );
      showLoader(false);
      return false;
    }
    try {
      const res = await Register(phone, password, otp, inviteCode);
      if (res.data.status) {
        // showToast(
        //   `<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M5 14.5s1.5 0 3.5 3.5c0 0 5.559-9.167 10.5-11" color="#fff"></path></svg>${res.data.message}`
        // );
        setTimeout(() => {
          const token = res.data.data.token;
          localStorage.setItem("token", token);
          localStorage.setItem("uid", res.data.data.user.uid);
          localStorage.setItem("bcRememberedPhone", phone);
          localStorage.setItem("bcRememberedPassword", password);
          navigate("/");
        }, 1000);
      } else {
        showToast(
          `<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="#fff" d="M13 2v14.5h-2V2zm-2 17h2.004v2.004H11z"></path></svg>${res.data.message}`
        );
      }
      showLoader(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        handleRegister();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleAgree = () => {
    setAgree(!agree);
  };

  const handlePcode = () => {
    setCphoneview(!cphoneview);
  };

  const getOtpData = async () => {
    $(".loader").removeClass("d-none");
    if (phone === "" || phone.length < 10) {
      showToast(
        `<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="#fff" d="M13 2v14.5h-2V2zm-2 17h2.004v2.004H11z"></path></svg>Invalid phone`
      );
      return false;
    }
    try {
      const res = await getOtp(phone);
      if (res.data.data.status) {
        setSentOtp(true);
        // showToast(
        //   `<svg width="25px" height="25px" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--emojione-monotone" preserveAspectRatio="xMidYMid meet" fill="#fff" stroke="#fff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M56 2L18.8 42.909L8 34.729H2L18.8 62L62 2z" fill="#fff"></path></g></svg>${res.data.message}`
        // );
      } else {
        showToast(
          `<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="#fff" d="M13 2v14.5h-2V2zm-2 17h2.004v2.004H11z"></path></svg>${res.data.message}`
        );
      }
      $(".loader").addClass("d-none");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const rememberedPhone = localStorage.getItem("bcRememberedPhone");
    const rememberedPassword = localStorage.getItem("bcRememberedPassword");

    if (rememberedPhone && rememberedPassword) {
      setPhone(rememberedPhone);
      setPassword(rememberedPassword);
    }

    setInviteCode(paramValue);
  }, []);

  return (
    <>
      <div className="container">
        <LoginHeader path="/" heading="Login" setOpenPopup={setOpenPopup} />
        <div className="info">
          <span className="info-heading">Register</span>
          <span className="info-text">
            Please register by phone number or email
          </span>
        </div>
        <div className="tab">
          <div
            className={`tab1 ${registerTab === "phone" ? "active" : ""}`}
            onClick={() => setRegisterTab("phone")}
          >

            <img src={Phones2} alt="" style={{ width: "30px" }} />

            <span className="phone-reg-text">Register your phone</span>
          </div>

        </div>

        <div className="login-container">
          <div className="input-container">
            <div className="label">
              <img src={Phones2} alt="" style={{ width: "30px" }} />
              <span>Phone number</span>
            </div>
            <div className="input-group2">
              <div
                className="input"
                style={{ position: "relative" }}
                onClick={handlePcode}
              >
                {pcode}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25px"
                  height="25px"
                  className={`${cphoneview ? "transforms active" : "transforms"}`}
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="none"
                    stroke="#768096"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m7 10l5 5m0 0l5-5"
                  />
                </svg>
                <div className={`pcode-dropdown ${cphoneview ? "show" : ""}`}>
                  <ul>
                    <li className="d-flex items-center">+91 <span style={{ width: "25px" }}></span> India ( भारत )</li>
                  </ul>
                </div>
              </div>
              <input
                type="number"
                className="d-input"
                value={phone}
                placeholder="Enter phone number"
                onChange={(e) => setPhone(e.target.value)}
                style={{ marginLeft: "10px" }}
              />
            </div>
          </div>
          {/* <div className="input-container">
              <div className="label">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25px"
                  height="25px"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#fababd"
                    fillRule="evenodd"
                    d="M3.378 5.082C3 5.62 3 7.22 3 10.417v1.574c0 5.638 4.239 8.375 6.899 9.536c.721.315 1.082.473 2.101.473V2c-.811 0-1.595.268-3.162.805L8.265 3c-3.007 1.03-4.51 1.545-4.887 2.082"
                    clipRule="evenodd"
                  />
                  <path
                    fill="#f95959"
                    d="M21 11.991v-1.574c0-3.198 0-4.797-.378-5.335c-.377-.537-1.88-1.052-4.887-2.081l-.573-.196C13.595 2.268 12.812 2 12 2v20c1.02 0 1.38-.158 2.101-.473C16.761 20.365 21 17.63 21 11.991"
                    opacity="1"
                  />
                </svg>
                <span>Verification code</span>
              </div>
              <div className="input-group">
                <input
                  type="number"
                  className="d-input"
                  value={otp}
                  placeholder="Enter verification code"
                  onChange={(e) => setOtp(e.target.value)}
                />
                <div className="send-otp" onClick={getOtpData}>
                  Send
                </div>
              </div>
            </div> */}
          <div className="input-container">
            <div className="label">
              <img src={Passwords} alt="" style={{ width: "30px" }} />
              <span>Set password</span>
            </div>
            <div className="input-group">
              <input
                value={password}
                className="d-input password"
                placeholder="Enter your password"
                onChange={(e) => setPassword(e.target.value)}
                type={viewPhonePass ? "text" : "password"}
              />
              <img
                src={`${viewPhonePass ? eyeOpen : eyeClose}`}
                alt=""
                onClick={() => setViewPhonePass(!viewPhonePass)}
              />
            </div>
          </div>
          <div className="input-container">
            <div className="label">
              <img src={Passwords} alt="" style={{ width: "30px" }} />
              <span>Confirm password</span>
            </div>
            <div className="input-group">
              <input
                className="d-input password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                type={viewPhoneCnfPass ? "text" : "password"}
                placeholder="Enter your confirm password"
              />
              <img
                src={`${viewPhoneCnfPass ? eyeOpen : eyeClose}`}
                alt=""
                onClick={() => setViewPhoneCnfPass(!viewPhoneCnfPass)}
              />
            </div>
          </div>
          <div className="input-container">
            <div className="label">
              <img src={Invites} alt="" style={{ width: "30px" }} />
              <span>Invite code</span>
            </div>
            <div className="input-group">
              <input
                type="number"
                className="d-input"
                value={paramValue ? paramValue : inviteCode}
                placeholder="Enter invite code"
                onChange={(e) => setInviteCode(e.target.value)}
              />
            </div>
          </div>

          <div className="input-container">
            <div className="label">
              <div
                className="d-flex align-center"
                style={{ gap: "10px" }}
                onClick={handleAgree}
              >
                <img
                  src={agree ? checkImg : checkCircle}
                  alt=""
                  className="check-img"
                />
                <span className="rp">I have read and agree</span>
              </div>
              <Link to="/account/About">
                <span style={{ color: "#f95959" }} className="rp">
                  【Privacy Agreement】
                </span>
              </Link>
            </div>
          </div>

          <div className="button-container">
            <div className="btn active" onClick={handleRegister}>
              Register
            </div>
            <div className="btn">
              <span
                style={{
                  fontSize: ".85rem",
                  color: "#768096",
                  marginRight: "10px",
                  fontWeight: "400",
                }}
              >
                I have an account{" "}
              </span>
              <Link to="/login">Login</Link>
            </div>
          </div>
        </div>

      </div>
      <Toast
        message={toast.message}
        isVisible={toast.isVisible}
        onClose={() => setToast({ isVisible: false, message: "" })}
      />



      <div className={`${openPopup ? "overlay" : "d-none"}`} onClick={() => setOpenPopup(false)}>

      </div>
      <div className={`off-canvas ${openPopup ? "open" : ""}`} onClick={() => setOpenPopup(false)}>
        <div className="off-canvas-container">
          <div className="off-canvas-content withdrawal-boxs">
            <div className='p-4 d-flex items-center justify-between' style={{ padding: " 10px" }}>
              <div className="d-flex items-center">
                <img src={En} alt="" style={{ width: "30px" }} />
                <span className="ms-1 " style={{ fontWeight: "500", fontSize: "16px" }}>EN</span>
              </div>
              <img
                src={checkImg}
                alt=""
                className="check-img"
              />

            </div>
            <p style={{ padding: "10px" }}>
              <hr style={{ borderColor: "#fff" }} />
            </p>
          </div>

        </div>
      </div>



    </>
  );
};

export default RegisterPage;
