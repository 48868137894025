import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import wingoBall from "../../../assets/img/wingoball.png";
import k3Ball from "../../../assets/img/k3ball.png";
import d5Ball from "../../../assets/img/d5ball.png";
import trxBall from "../../../assets/img/trxball.png";
import { xgameLogo } from "../../../assets/media";
import { getUser, getDepositList1, getRedeemHistory, } from "../../../utils/UserController";

const Lottery = ({ viewall }) => {
  const [openP, setOpenp] = useState(false)
  const navigate = useNavigate();

  const handleGo = async (d) => {
    navigate(d)
    // try{
    //   const response = await getRedeemHistory();
    //  const res=await getDepositList1()

    //  if(res?.data?.status || response.data.data?.length>0){

    //  }

    // }catch(error){
    //    setOpenp(true)
    //     console.log("err",error.message)
    // }

  }

  const subm = () => {
    navigate("/wallet/Recharge")
  }

  return (
    <>

      {openP && (

        <div className="recharge-sect">
          <div className="recharge-first">

            <h3>Tips</h3>

            <p style={{ lineHeight: "18px" }
            }>Game requires recharge to enter
              <br />
              Recharge now?
            </p>


            <div className="cons">
              <span onClick={() => setOpenp(false)}>
                Cancel
              </span>
              <span className="cirfm" onClick={subm}>
                Confirm
              </span>
            </div>

          </div>
        </div>
      )}


      <div className="lottery-main-container d-flex flex-col align-center">
        <Link className="link" to={"/home/AllLotteryGames/WinGo"}>
          <div className="lottery-box d-flex justify-between" >
            <div className="d-flex flex-col box-left">
              <span className="box-heading">Win Go</span>
              <div className="d-flex flex-col box-text">
                <span>Guess Number</span>
                <span>Green/Red/Violet to win</span>
              </div>
            </div>
            <img src={wingoBall} alt="" />
          </div>
        </Link>
        <Link className="link" to={"/home/AllLotteryGames/WinTrx"}>
          <div className="lottery-box d-flex justify-between" >
            <div className="d-flex flex-col box-left">
              <span className="box-heading">Trx Win</span>
              <div className="d-flex flex-col box-text">
                <span>Guess Number</span>
                <span>Small/Big/Odd/Even</span>
              </div>
            </div>
            <img src={trxBall} alt="" />
          </div>
        </Link>
        <Link className="link" to={"/home/AllLotteryGames/K3"}>
          <div className="lottery-box d-flex justify-between">
            <div className="d-flex flex-col box-left">
              <span className="box-heading">K3</span>
              <div className="d-flex flex-col box-text">
                <span>Guess Number</span>
                <span>Small/Big/Odd/Even</span>
              </div>
            </div>
            <img src={k3Ball} alt="" style={{ width: "120px" }} />
          </div>
        </Link>
        <Link className="link" to={"/home/AllLotteryGames/5D"}>
          <div className="lottery-box d-flex justify-between" >
            <div className="d-flex flex-col box-left">
              <span className="box-heading">5D</span>
              <div className="d-flex flex-col box-text">
                <span>Guess Number</span>
                <span>Small/Big/Odd/Even</span>
              </div>
            </div>
            <img src={d5Ball} alt="" style={{ width: "110px" }} />
          </div>
        </Link>

        {viewall ? (
          <Link to="/home/AllGames">
            <div className="viewallbtn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25px"
                height="25px"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#f95959"
                  d="M10.995 4.68v3.88A2.44 2.44 0 0 1 8.545 11h-3.86a2.38 2.38 0 0 1-1.72-.72a2.41 2.41 0 0 1-.71-1.72V4.69a2.44 2.44 0 0 1 2.43-2.44h3.87a2.42 2.42 0 0 1 1.72.72a2.39 2.39 0 0 1 .72 1.71m10.75.01v3.87a2.46 2.46 0 0 1-2.43 2.44h-3.88a2.5 2.5 0 0 1-1.73-.71a2.44 2.44 0 0 1-.71-1.73V4.69a2.39 2.39 0 0 1 .72-1.72a2.42 2.42 0 0 1 1.72-.72h3.87a2.46 2.46 0 0 1 2.44 2.44m0 10.75v3.87a2.46 2.46 0 0 1-2.43 2.44h-3.88a2.5 2.5 0 0 1-1.75-.69a2.42 2.42 0 0 1-.71-1.73v-3.87a2.391 2.391 0 0 1 .72-1.72a2.421 2.421 0 0 1 1.72-.72h3.87a2.46 2.46 0 0 1 2.44 2.44zm-10.75.01v3.87a2.46 2.46 0 0 1-2.45 2.43h-3.86a2.42 2.42 0 0 1-2.43-2.43v-3.87A2.46 2.46 0 0 1 4.685 13h3.87a2.49 2.49 0 0 1 1.73.72a2.45 2.45 0 0 1 .71 1.73"
                />
              </svg>

              <span>View All</span>
            </div>
          </Link>
        ) : (
          ""
        )}
      </div>
    </>

  )
};

export default Lottery;
