import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { getUser, Login } from "../../utils/UserController";
import LoginHeader from "../common/LoginHeader";
import forgetLockImg from "../../assets/logo/forgetlock.png";
import { checkCircle, checkImg, eyeClose, eyeOpen } from "../../assets/media";
import Toast from "../components/Toast";
import { showLoader } from "../../utils/Utils";
import customer from "../../assets/img/customer.svg"
import setPasswordIcon from "../../assets/img/setPassword.svg"
import Phones2 from "../../assets/icons/phone2.svg"
import Phones from "../../assets/icons/phone.svg"
import Passwords from "../../assets/icons/password.svg"
import En from "../../assets/icons/en.png"

const LoginPage = () => {
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginTab, setLoginTab] = useState("phone");
  const [viewPhonePass, setViewPhonePass] = useState(false);
  const [viewEmailPass, setViewEmailPass] = useState(false);
  const navigate = useNavigate();
  const [pcode, setPcode] = useState("+91");
  const [cphoneview, setCphoneview] = useState(false);
  const [openPopup, setOpenPopup] = useState(false)

  const [agree, setAgree] = useState(true);
  const handleAgree = () => {
    setAgree(!agree);
  };

  const [toast, setToast] = useState({
    isVisible: false,
    message: "",
  });

  const showToast = (message) => {
    setToast({ isVisible: true, message });
    setTimeout(() => {
      setToast({ isVisible: false, message: "" });
    }, 3000); // Hide after 3 seconds
  };

  const handlePcode = () => {
    setCphoneview(!cphoneview);
  };

  const handleLogin = async () => {
    if (loginTab === "email") {
      showToast(
        `<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="#fff" d="M13 2v14.5h-2V2zm-2 17h2.004v2.004H11z"></path></svg>Please login with your phone number`
      );
      return false;
    }

    if (
      (phone === "" || phone.length < 10) &&
      (password === "" || password.length < 6)
    ) {
      showToast(
        `<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="#fff" d="M13 2v14.5h-2V2zm-2 17h2.004v2.004H11z"></path></svg>Enter valid phone or password`
      );
      return false;
    }
    showLoader(true);
    const response = await Login(phone, password);

    if (response.data.status) {
      const token = response.data.data.token;
      localStorage.setItem("token", token);
      localStorage.setItem("uid", response.data.data.user.uid);
      localStorage.setItem("bcRememberedPhone", phone);
      localStorage.setItem("bcRememberedPassword", password);

      localStorage.removeItem("hasSeenNotification");
      localStorage.removeItem("soundEnabled");

      navigate("/");
    } else {
      showToast(
        `<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="#fff" d="M13 2v14.5h-2V2zm-2 17h2.004v2.004H11z"></path></svg>${response.data.message}`
      );
    }
    showLoader(false);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        handleLogin();
      }
    };
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [phone, password]);

  useEffect(() => {
    const rememberedPhone = localStorage.getItem("bcRememberedPhone");
    const rememberedPassword = localStorage.getItem("bcRememberedPassword");

    if (rememberedPhone && rememberedPassword) {
      setPhone(rememberedPhone);
      setPassword(rememberedPassword);
    }

    const getUserData = async () => {
      showLoader(true);
      try {
        const res = await getUser();
        if (res.data.status) {
          showToast(
            `<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="#fff" d="M13 2v14.5h-2V2zm-2 17h2.004v2.004H11z"></path></svg>${res.data.message}`
          );
        }
        showLoader(false);
      } catch (error) {
        console.log(error);
      }
    };
    getUserData();
  }, []);

  return (
    <>
      <div className="container">
        <LoginHeader path="/" heading="Login" setOpenPopup={setOpenPopup} />
        <div className="info">
          <span className="info-heading">Log in</span>
          <span className="info-text">
            Please log in with your phone number or email
          </span>
          <span className="info-text">
            If you forget your password, please contact customer service
          </span>
        </div>
        <div className="tab">
          <div
            className={`tab1 ${loginTab === "phone" ? "active" : ""}`}
            onClick={() => setLoginTab("phone")}
          >
            {loginTab === "phone" ?
              <img src={Phones2} alt="" style={{ width: "30px" }} /> : <img src={Phones} alt="" style={{ width: "30px" }} />
            }

            <span className="phone-login-text">Login with phone</span>
          </div>
          <div
            className={`tab2 ${loginTab === "email" ? "active" : ""}`}
            onClick={() => setLoginTab("email")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25px"
              height="25px"
              viewBox="0 0 36 36"
            >
              <path
                fill="#768096"
                d="M32.33 6a2 2 0 0 0-.41 0h-28a2 2 0 0 0-.53.08l14.45 14.39Z"
                className="clr-i-solid clr-i-solid-path-1 color"
              />
              <path
                fill="#768096"
                d="m33.81 7.39l-14.56 14.5a2 2 0 0 1-2.82 0L2 7.5a2 2 0 0 0-.07.5v20a2 2 0 0 0 2 2h28a2 2 0 0 0 2-2V8a2 2 0 0 0-.12-.61M5.3 28H3.91v-1.43l7.27-7.21l1.41 1.41Zm26.61 0h-1.4l-7.29-7.23l1.41-1.41l7.27 7.21Z"
                className="clr-i-solid clr-i-solid-path-2 color"
              />
              <path fill="none" d="M0 0h36v36H0z" />
            </svg>
            <span className="email-login-text">Email Login</span>
          </div>
        </div>
        {loginTab === "phone" ? (
          <div className="login-container">
            <div className="input-container">
              <div className="label">
                <img src={Phones2} alt="" style={{ width: "30px" }} />
                <span>Phone number</span>
              </div>
              <div className="input-group2">
                <div
                  className="input"
                  style={{ position: "relative" }}
                  onClick={handlePcode}
                >
                  {pcode}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25px"
                    height="25px"
                    className={`${cphoneview ? "transforms active" : "transforms"}`}
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="none"
                      stroke="#768096"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m7 10l5 5m0 0l5-5"
                    />
                  </svg>
                  <div className={`pcode-dropdown ${cphoneview ? "show" : ""}`}>
                    <ul>
                      <li className="d-flex items-center">+91 <span style={{ width: "25px" }}></span> India ( भारत )</li>
                    </ul>
                  </div>
                </div>
                <input
                  type="number"
                  className="d-input"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  style={{ marginLeft: "10px" }}
                  placeholder="Enter phone number"
                />
              </div>
            </div>

            <div className="input-container">
              <div className="label">
                <img src={Passwords} alt="" style={{ width: "30px" }} />
                <span>Password</span>
              </div>
              <div className="input-group">
                <input
                  type={viewPhonePass ? "text" : "password"}
                  className="d-input password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter your password"
                />
                <img
                  src={`${viewPhonePass ? eyeOpen : eyeClose}`}
                  alt=""
                  onClick={() => setViewPhonePass(!viewPhonePass)}
                />
              </div>
            </div>
            <div className="input-container">
              <div className="label">
                <div
                  className="d-flex align-center"
                  style={{ gap: "10px" }}
                  onClick={handleAgree}
                >
                  <img
                    src={agree ? checkImg : checkCircle}
                    alt=""
                    className="check-img"
                  />
                  <span className="rp">Remember password</span>
                </div>
              </div>
            </div>
            <div className="button-container">
              <div className={`${phone ? "active" : "bg-phone-gray"} btn `} onClick={handleLogin}>
                Log in
              </div>
              <div className="btn">
                <Link to="/register">Register</Link>
              </div>
            </div>
            <div className="other-container">
              <div className="other1">
                <Link to="/promotion/CustomerSupport">
                  <img src={forgetLockImg} alt="" />
                </Link>
                <span>Forgot Password</span>
              </div>
              <div className="other2">
                <Link to="/promotion/CustomerSupport">
                  <img src={customer} alt="" />
                </Link>
                <span>Customer Service</span>
              </div>
            </div>
          </div>
        ) : (
          <div className="login-container">
            <div className="input-container">
              <div className="label">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25px"
                  height="25px"
                  viewBox="0 0 36 36"
                >
                  <path
                    fill="#f95959"
                    d="M32.33 6a2 2 0 0 0-.41 0h-28a2 2 0 0 0-.53.08l14.45 14.39Z"
                    className="clr-i-solid clr-i-solid-path-1 color"
                  />
                  <path
                    fill="#f95959"
                    d="m33.81 7.39l-14.56 14.5a2 2 0 0 1-2.82 0L2 7.5a2 2 0 0 0-.07.5v20a2 2 0 0 0 2 2h28a2 2 0 0 0 2-2V8a2 2 0 0 0-.12-.61M5.3 28H3.91v-1.43l7.27-7.21l1.41 1.41Zm26.61 0h-1.4l-7.29-7.23l1.41-1.41l7.27 7.21Z"
                    className="clr-i-solid clr-i-solid-path-2 color"
                  />
                  <path fill="none" d="M0 0h36v36H0z" />
                </svg>
                <span>Mail</span>
              </div>
              <div className="input-group">
                <input
                  type="text"
                  className="d-input"
                  value={email}
                  placeholder="Enter your email address"
                  onChange={(e) => setEmail(e.target.value)}
                  style={{ marginLeft: "10px" }}
                />
              </div>
            </div>

            <div className="input-container">
              <div className="label">
                <img src={Passwords} alt="" style={{ width: "30px" }} />
                <span>Password</span>
              </div>
              <div className="input-group">
                <input
                  type={viewEmailPass ? "text" : "password"}
                  className="d-input password"
                  value={password}
                  placeholder="Enter your password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <img
                  src={`${viewEmailPass ? eyeOpen : eyeClose}`}
                  alt=""
                  onClick={() => setViewEmailPass(!viewEmailPass)}
                />
              </div>
            </div>
            <div className="input-container">
              <div className="label">
                <div
                  className="d-flex align-center"
                  style={{ gap: "10px" }}
                  onClick={handleAgree}
                >
                  <img
                    src={agree ? checkImg : checkCircle}
                    alt=""
                    className="check-img"
                  />
                  <span className="rp">Remember password</span>
                </div>
              </div>
            </div>
            <div className="button-container">
              <div className={`${email ? "active" : "bg-phone-gray"} btn `} onClick={handleLogin}>
                Log in
              </div>
              <div className="btn">
                <Link to="/register">Register</Link>
              </div>
            </div>
            <div className="other-container">
              <div className="other1">
                <Link to="/promotion/CustomerSupport">
                  <img src={forgetLockImg} alt="" />
                </Link>
                <span>Forgot Password</span>
              </div>
              <div className="other2">
                <Link to="/promotion/CustomerSupport">
                  <img src={customer} alt="" />
                </Link>
                <span>Customer Service</span>
              </div>
            </div>
          </div>
        )}
      </div>
      <Toast
        message={toast.message}
        isVisible={toast.isVisible}
        onClose={() => setToast({ isVisible: false, message: "" })}
      />

      <div className={`${openPopup ? "overlay" : "d-none"}`} onClick={() => setOpenPopup(false)}>

      </div>
      <div className={`off-canvas ${openPopup ? "open" : ""}`} onClick={() => setOpenPopup(false)}>
        <div className="off-canvas-container">
          <div className="off-canvas-content withdrawal-boxs">
            <div className='p-4 d-flex items-center justify-between' style={{ padding: " 10px" }}>
              <div className="d-flex items-center">
                <img src={En} alt="" style={{ width: "30px" }} />
                <span className="ms-1 " style={{ fontWeight: "500", fontSize: "16px" }}>EN</span>
              </div>
              <img
                src={checkImg}
                alt=""
                className="check-img"
              />

            </div>
            <p style={{ padding: "10px" }}>
              <hr style={{ borderColor: "#fff" }} />
            </p>
          </div>

        </div>
      </div>


      <div className="size-box-100"></div>


    </>
  );
};

export default LoginPage;
