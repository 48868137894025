import React from "react";
import "../../assets/css/notification.css";
import CommonHeader from "../common/CommonHeader";
import { noData } from "../../assets/media";

const Notification = () => {
  const arr = [
  {
    title: "UPI Payment System Issues",
    msg: "Due to ongoing issues with the UPI payment system across all banks in India, you may encounter difficulties with fund transfers following a deposit order. Kindly retry the UPI transfer if you face any problems. Alternatively, we advise utilizing other available payment methods to finalize your transaction. We apologize for the inconvenience and appreciate your patience.",
    datetime: "2024-07-21 11:20:00"
  },
  {
    title: "in3win Official Website!!",
    msg: "in3win Official Website To visit our official website, be sure to use the link below, https://www.in3win.site/ Please remember! Make sure not to provide personal data and personal transactions in any form and for any reason to other parties on behalf of in3win. Our side does not make private chats or calls to all members. Please inform all Referrals/other Members about this to avoid fraud. Thank you for your attention and cooperation.",
    datetime: "2023-12-22 08:59:53"
  },
  {
    title: "Avoid fraudulents",
    msg: "Stay alert for fraudulent sites that look like our in3win official page, refrain from transferring money to anyone, even agents, and keep your payment receipts and UTR numbers private.",
    datetime: "2024-08-13 20:48:37"
  },
  {
    title: "Announcement about Deposit",
    msg: "Also, be aware that when transferring money, you may face delays or failures due to an unstable bank in India. If it fails once or twice, even if you are using PhonePe, you might want to try again a few times. Sometimes payments can be delayed or lost, so we recommend sending via PAYTM or other payment methods. Your satisfaction is our priority. Thank you for your understanding and continued support.",
    datetime: "2024-06-04 20:46:13"
  },
  {
    title: "Welcome to in3win",
    msg: "The most fun online lottery platform! We are happy to have you join us. As a new member, you are qualified for a variety of exclusive bonuses. Don't pass up this opportunity to increase your winnings! You can also earn additional bonuses by inviting friends to join in3win. Share your referral link with them and earn rewards together. We can't wait for you to win big! Sincerely, The in3win Team",
    datetime: "2024-03-12 21:47:03"
  },
  {
    title: "Safe Recharge Tips",
    msg: "All Recharge payment methods on the in3win site are only available in the Recharge menu on the official website. Make sure to make a Recharge only through our official website and don't trust any party on behalf of in3win. If you find any discrepancies or suspicious behavior, please contact our customer service immediately for confirmation. We urge all members not to believe or be tempted by other promotions outside our site.",
    datetime: "2023-12-22 09:01:45"
  }
];
  return (
    <div className="container">
      <CommonHeader
        path="/"
        heading="Notification"
        svg={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20px"
            height="20px"
            viewBox="0 0 24 24"
          >
            <path
              fill="var(--lightText)"
              d="m3.55 12l7.35 7.35q.375.375.363.875t-.388.875t-.875.375t-.875-.375l-7.7-7.675q-.3-.3-.45-.675T.825 12t.15-.75t.45-.675l7.7-7.7q.375-.375.888-.363t.887.388t.375.875t-.375.875z"
            />
          </svg>
        }
      />
      <div className="msg-content">
        {arr.length > 0 ? (
          arr.map((value, index) => (
            <div className="msg-container" key={index}>
              <div className="d-flex justify-between align-center">
                <div className="d-flex align-center" style={{ gap: "5px" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="35px"
                    height="35px"
                    viewBox="0 0 1024 1024"
                  >
                    <path
                      fill="#f95959"
                      d="M880 112c-3.8 0-7.7.7-11.6 2.3L292 345.9H128c-8.8 0-16 7.4-16 16.6v299c0 9.2 7.2 16.6 16 16.6h101.6c-3.7 11.6-5.6 23.9-5.6 36.4c0 65.9 53.8 119.5 120 119.5c55.4 0 102.1-37.6 115.9-88.4l408.6 164.2c3.9 1.5 7.8 2.3 11.6 2.3c16.9 0 32-14.2 32-33.2V145.2C912 126.2 897 112 880 112M344 762.3c-26.5 0-48-21.4-48-47.8c0-11.2 3.9-21.9 11-30.4l84.9 34.1c-2 24.6-22.7 44.1-47.9 44.1"
                    />
                  </svg>
                  <span className="msg-heading">{value.title}</span>
                </div>
              </div>
              <span className="noti-msg">{value.msg}</span>
              <span className="login-time">{value.datetime}</span>
            </div>
          ))
        ) : (
          <div className="empty">
            <img src={noData} alt="" className="noData" />
            <span>No Data</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Notification;
