import React, { useState } from "react";
import CustomHeader from "../../common/CustomHeader";


const NewSubordinate = () => {
const [tabs,setTabs]=useState("Today")

  return (
    <>
      <CustomHeader
        path={"/promotion"}
        bg={"var(--themeBlack)"}
        color={"var(--mainText)"}
        heading={"New subordinates"}
      />
      <div className="select-container">        
<div className="new-subordinate">
<button className={tabs==="Today"?"active":""} onClick={()=>setTabs("Today")}>Today</button>
<button className={tabs==="yesterday"?"active":""} onClick={()=>setTabs("yesterday")}>Yesterday</button>
<button className={tabs==="month"?"active":""} onClick={()=>setTabs("month")}>This month</button>
</div>

      </div>
    </>
  );
};

export default NewSubordinate;
