import React from "react";
import CustomHeader from "../../common/CustomHeader";
import "../../../assets/css/promotion.css";
import { rebateBg, ruleHeadingBg } from "../../../assets/media";
import { formatAlfaNumber } from "../../../utils/Utils";
import { Link } from "react-router-dom";

const PromotionRule = () => {
  const levels = [
    { level: "L0", number: 0, betting: 0, deposit: 0 },
    { level: "L1", number: 5, betting: 500000, deposit: 100000 },
    { level: "L2", number: 10, betting: 1000000, deposit: 200000 },
    { level: "L3", number: 15, betting: 2500000, deposit: 500000 },
    { level: "L4", number: 20, betting: 3500000, deposit: 700000 },
    { level: "L5", number: 25, betting: 5000000, deposit: 1000000 },
    { level: "L6", number: 30, betting: 10000000, deposit: 2000000 },
    { level: "L7", number: 100, betting: 100000000, deposit: 20000000 },
    { level: "L8", number: 500, betting: 500000000, deposit: 100000000 },
    { level: "L9", number: 1000, betting: 1000000000, deposit: 200000000 },
    { level: "L10", number: 5000, betting: 1500000000, deposit: 300000000 },
  ];
  const rules = [
    `There are 6 subordinate levels in inviting friends, if A invites B,
          then B is a level 1 subordinate of A. If B invites C, then C is a
          level 1 subordinate of B and also a level 2 subordinate of A. If C
          invites D, then D is a level 1 subordinate of C, at the same time a
          level 2 subordinate of B and also a level 3 subordinate of A.`,
    `When inviting friends to register, you must send the invitation link provided or enter the invitation code manually so that your friends become your level 1 subordinates.`,
    `The invitee registers via the inviter's invitation code and completes the deposit, shortly after that the commission will be received immediately`,
    `The calculation of yesterday's commission starts every morning at 01:00. After the commission calculation is completed, the commission is rewarded to the wallet and can be viewed through the commission collection record.`,
    `Commission rates vary depending on your agency level on that day<br>Number of Teams: How many downline deposits you have to date. <br>Team Deposits: The total number of deposits made by your downline in one day. <br>Team Deposit: Your downline deposits within one day.`,
    ``,
    `TOP20 commission rankings will be randomly awarded with  a separate bonus`,
    `The final interpretation of this activity belongs to in3win`,
  ];
  return (
    <>
      <CustomHeader
        path={"/promotion"}
        bg={"var(--themeBlack)"}
        color={"var(--mainText)"}
        heading={"Rule"}
      />
      <div className="rule-header">
        <span className="heading">【Promotion partner】program</span>
        <span className="des">This activity is valid for a long time</span>
      </div>

      {rules.map((rule, index) => (
        <>
          {index === 5 ? (
            <>
              <div className="table-container game-rule-table">
                <div className="table">
                  <div className="table-header">
                    <div className="table-row">
                      <div className="table-col">Rebate level</div>
                      <div className="table-col">Team Number</div>
                      <div className="table-col">Team Betting</div>
                      <div className="table-col">Team Deposit</div>
                    </div>
                  </div>
                  <div className="table-body">
                    {levels.map((v, i) => (
                      <div
                        className={`table-row ${i % 2 === 1 ? "odd" : "even"}`}
                        key={i}
                      >
                        <div className="table-col">
                          <img src={rebateBg} alt="" />
                          <div className="level-txt">L{i}</div>
                        </div>
                        <div className="table-col">
                          {formatAlfaNumber(v.number)}
                        </div>
                        <div className="table-col">
                          {formatAlfaNumber(v.betting)}
                        </div>
                        <div className="table-col">
                          {formatAlfaNumber(v.deposit)}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="p-rule-container" key={index}>
                <div
                  className="heading"
                  style={{ backgroundImage: `url('${ruleHeadingBg}')` }}
                >
                  {index + 1}
                </div>
                <div className="content">
                  The commission percentage depends on the membership level. The
                  higher the membership level, the higher the bonus percentage.
                  Different game types also have different payout percentages.{" "}
                  <br />
                  The commission rate is specifically explained as follows.{" "}
                  <br />
                  <Link to={"/promotion/RebateRatio"}>
                    <span style={{ color: "#f95959", fontWeight: "bold" }}>
                      View rebate ratio {">>"}
                    </span>
                  </Link>
                </div>
              </div>
            </>
          ) : (
            <div className="p-rule-container" key={index}>
              <div
                className="heading"
                style={{ backgroundImage: `url('${ruleHeadingBg}')` }}
              >
                {index + 1}
              </div>
              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: rule }}
              ></div>
            </div>
          )}
        </>
      ))}
      <div className="size-box-50"></div>
    </>
  );
};

export default PromotionRule;
