import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
import "../../assets/css/main.css";

const ListPicker = ({ openList, returnItem, list, heading }) => {
  const [isOpen, setIsOpen] = useState(true); // Default is false
  const [activeItem, setActiveItem] = useState(list[0]);
  const itemRef = useRef(null);

  // Scroll to the active item
  useEffect(() => {
    if (activeItem !== null && itemRef.current) {
      const index = list.indexOf(activeItem);
      if (index !== -1) {
        const activeElement = itemRef.current.children[index];
        const containerHeight = itemRef.current.offsetHeight;
        const elementHeight = activeElement.offsetHeight;
        const scrollPosition =
          activeElement.offsetTop - (containerHeight / 2 - elementHeight / 2);
        itemRef.current.scrollTo({
          top: scrollPosition,
          behavior: "smooth",
        });
      }
    }
  }, [activeItem, list]);

  // Handle item selection and confirm button
  const handleSelectedItem = () => {
    if (returnItem && activeItem) {
      returnItem(activeItem);
    }
    toggleOffCanvas();
  };

  const toggleOffCanvas = () => {
    if (!isOpen) {
      $(".overlay").removeClass("d-none");
      $(".container").css("overflow", "hidden");
    } else {
      $(".overlay").addClass("d-none");
      $(".container").css("overflow", "");
      $(".wingo-model-content1").prop("class", "wingo-model-content1");
    }
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    toggleOffCanvas();
  }, [openList]);

  return (
    <div className={`off-canvas ${isOpen ? "open" : ""}`}>
      <div className="option-item-container">
        <div className="option-item-header">
          <span
            style={{ color: "#969799", fontSize: ".9rem" }}
            onClick={toggleOffCanvas}
          >
            Cancel
          </span>
          <span style={{ fontWeight: "700" }}>Select {heading}</span>
          <span
            style={{ color: "#f95959", fontSize: ".9rem" }}
            onClick={handleSelectedItem}
          >
            Confirm
          </span>
        </div>
        <div className="option-item-content">
          <div className="option-items">
            <div className="option-item-boxes" ref={itemRef}>
              {list.map((item, index) => (
                <div
                  className={`option-item ${
                    item === activeItem ? "active" : ""
                  }`}
                  key={index}
                  onClick={() => setActiveItem(item)}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListPicker;
