import React, { useEffect, useState } from "react";
import CommonHeader from "../../common/CommonHeader";
import { formatDate, showLoader } from "../../../utils/Utils";
import { getNews } from "../../../utils/UserController";
import { noData } from "../../../assets/media";

const Notify = () => {
  const [news, setNews] = useState({});
  
  const arr = [
  {
    title: "Welcome To in3win!",
    msg: "Welcome to the in3win! Greetings, Gamers and Enthusiasts! The in3win is more than just a platform for gaming. We invite you to join us, you'll find a variety of games, promo, bonus, luxury gold awards. Register now and win.",
    datetime: "2024-04-08 23:45:44"
  },
  {
    title: "Avoid Scammer And Phishing Links",
    msg: "Please be sure to always use our official website for playing the games with the following link, https://in3win.site. Please always check our official link to access our website and avoid scammers and phishing links.",
    datetime: "2024-05-25 17:16:52"
  },
  {
    title: "Beware Of Fraud And Scammers",
    msg: "If your deposit is not received, please send it directly to in3win Self-service Center (https://www.in3win.site) and wait until it is processed. Do not send to another person or trust anyone claiming to represent in3win. Always verify our website's authenticity through the official community channels. Your safety and trust is our priority.",
    datetime: "2024-04-08 23:45:27"
  }
];

  useEffect(() => {
    const getNewsData = async () => {
      showLoader(true);
      try {
        const res = await getNews();
        setNews(res.data.data);
        showLoader(false);
      } catch (error) {
        console.log(error);
      }
    };
    getNewsData();
  }, []);

  return (
    <div className="container news-container">
      <CommonHeader
        path="/"
        heading="News"
        svg={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20px"
            height="20px"
            viewBox="0 0 24 24"
          >
            <path
              fill="var(--lightText)"
              d="m3.55 12l7.35 7.35q.375.375.363.875t-.388.875t-.875.375t-.875-.375l-7.7-7.675q-.3-.3-.45-.675T.825 12t.15-.75t.45-.675l7.7-7.7q.375-.375.888-.363t.887.388t.375.875t-.375.875z"
            />
          </svg>
        }
      />
      <div className="msg-content">
        {arr.length > 0 ? (
          arr.map((value, index) => (
            <div className="msg-container" key={index}>
              <div className="d-flex justify-between align-center">
                <div className="d-flex align-center" style={{ gap: "5px" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="35px"
                    height="35px"
                    viewBox="0 0 1024 1024"
                  >
                    <path
                      fill="#f95959"
                      d="M880 112c-3.8 0-7.7.7-11.6 2.3L292 345.9H128c-8.8 0-16 7.4-16 16.6v299c0 9.2 7.2 16.6 16 16.6h101.6c-3.7 11.6-5.6 23.9-5.6 36.4c0 65.9 53.8 119.5 120 119.5c55.4 0 102.1-37.6 115.9-88.4l408.6 164.2c3.9 1.5 7.8 2.3 11.6 2.3c16.9 0 32-14.2 32-33.2V145.2C912 126.2 897 112 880 112M344 762.3c-26.5 0-48-21.4-48-47.8c0-11.2 3.9-21.9 11-30.4l84.9 34.1c-2 24.6-22.7 44.1-47.9 44.1"
                    />
                  </svg>
                  <span className="msg-heading">{value.title}</span>
                </div>
              </div>
              <span className="noti-msg">{value.msg}</span>
              <span className="login-time">{value.datetime}</span>
            </div>
          ))
        ) : (
          <div className="empty">
            <img src={noData} alt="" className="noData" />
            <span>No Data</span>
          </div>
        )}
      </div>
      <span className="no-more">No more</span>
    </div>
  );
};

export default Notify;

