import React from "react";
import CustomHeader from "../../common/CustomHeader";
import { about } from "../../../assets/media";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <>
      <CustomHeader
        path={"/account"}
        bg={"var(--main_gradient-color)"}
        color={"#fff"}
        heading={"About"}
      />
      <div
        className="hero-header"
        style={{
          backgroundImage: `url(${about}), linear-gradient(94deg, #f99937 2.72%, #ff6922 43.54%, #ff8039 98.54%)`,
          height: "200px",
          backgroundSize: "100% 100%",
        }}
      ></div>
      <div className="links">
        <Link to={"/about/ConfidentialityAgreement"}>
          <div className="sub-link">
            <div className="d-flex align-center" style={{ gap: "10px" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25px"
                height="25px"
                viewBox="0 0 1024 1024"
              >
                <path
                  fill="#f95959"
                  d="M288 384h448v64H288zm96-128h256v64H384zM131.456 512H384v128h256V512h252.544L721.856 192H302.144zM896 576H704v128H320V576H128v256h768zM275.776 128h472.448a32 32 0 0 1 28.608 17.664l179.84 359.552A32 32 0 0 1 960 519.552V864a32 32 0 0 1-32 32H96a32 32 0 0 1-32-32V519.552a32 32 0 0 1 3.392-14.336l179.776-359.552A32 32 0 0 1 275.776 128"
                ></path>
              </svg>
              <span className="sub-link-txt" style={{ fontSize: "1rem" }}>
                Confidentiality Agreement
              </span>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18px"
              height="18px"
              viewBox="0 0 24 24"
            >
              <path
                fill="#a8a8a8"
                d="m8.006 21.308l-1.064-1.064L15.187 12L6.942 3.756l1.064-1.064L17.314 12z"
              />
            </svg>
          </div>
        </Link>
        <Link to={"/about/RiskDisclosureAgreement"}>
          <div className="sub-link">
            <div className="d-flex align-center" style={{ gap: "10px" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25px"
                height="25px"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#f95959"
                  d="M18 22a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2h-6v7L9.5 7.5L7 9V2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2z"
                ></path>
              </svg>
              <span className="sub-link-txt" style={{ fontSize: "1rem" }}>
                Risk Disclosure Agreement
              </span>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18px"
              height="18px"
              viewBox="0 0 24 24"
            >
              <path
                fill="#a8a8a8"
                d="m8.006 21.308l-1.064-1.064L15.187 12L6.942 3.756l1.064-1.064L17.314 12z"
              />
            </svg>
          </div>
        </Link>
      </div>
    </>
  );
};

export default About;
