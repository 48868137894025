import React from "react"
import changlong1 from "../../assets/img/changlong1.svg";

const Dragon = () => {
    
     return (

  <div className="not-floating-msg">
        <img src={changlong1} alt="changlong" />
        </div>


)
}

export default Dragon;