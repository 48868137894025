import React, { useEffect, useState } from "react";
import $ from "jquery";
import { changeUserBank, getUserBank } from "../../../utils/UserController";
import { Link, useNavigate } from "react-router-dom";
import CustomHeader from "../../common/CustomHeader";
import { bankAddIcon } from "../../../assets/media";
import { formatString } from "../../../utils/Utils";
import "../../../assets/css/wallet.css";
import Toast from "../Toast";

const BankAccount = () => {
  const [userBanks, setUserBanks] = useState([]);
  const [currentBank, setCurrentBank] = useState(null);
  const navigate = useNavigate();

  const [toast, setToast] = useState({
    isVisible: false,
    message: "",
  });

  const showToast = (message) => {
    setToast({ isVisible: true, message });
    setTimeout(() => {
      setToast({ isVisible: false, message: "" });
    }, 3000); // Hide after 3 seconds
  };

  useEffect(() => {
    let isMounted = true; // flag to handle component unmounting

    const getUserBankData = async () => {
      try {
        const res = await getUserBank();
        if (res.status === 200) {
          if (res.data.status === "expired") {
            navigate("/login");
            return;
          }
          if (isMounted) {
            setUserBanks(res.data.data);
            const defaultBank = res.data.data.find(
              (bank) => bank.current === 1
            );
            if (defaultBank) {
              setCurrentBank(defaultBank.id);
            }
          }
        }
      } catch (error) {
        console.error("Error fetching bank data:", error);
        navigate("/login");
      }
    };

    getUserBankData();

    return () => {
      isMounted = false; // cleanup function
    };
  }, [navigate]);

  const handleBankChange = async (id) => {
    $(".loader").removeClass("d-none");

    try {
      const res = await changeUserBank(id);
      if (res.status === 200) {
        if (res.data.status === "expired") {
          navigate("/login");
          return;
        }
        $(".loader").addClass("d-none");
        setCurrentBank(id);
      }
    } catch (err) {
      if (err.message === "Resource not found") {
        navigate("/404");
      } else {
        showToast(err.message);
      }
    }
  };

  return (
    <>
      <CustomHeader
        path={"/wallet"}
        bg={"var(--themeBlack)"}
        color={"var(--mainText)"}
        heading={"Bank Account"}
      />
      <div className="banks-container">
        {userBanks.map((item) => (
          <div
            key={item.id}
            className="bank-container"
            onClick={() => handleBankChange(item.id)}
          >
            <div className="container-header"></div>
            <div className="container-row">
              <div className="container-col">Bank name</div>
              <div className="container-col">{item.bank_name}</div>
            </div>
            <div className="container-row">
              <div className="container-col">Bank account number</div>
              <div className="container-col">
                {formatString(item.account_no, 6, 3)}
              </div>
            </div>
            <div className="container-row">
              <div className="container-col">Phone number</div>
              <div className="container-col">
                {formatString(item.phone_no, 5, 2)}
              </div>
            </div>
            <div className="bank-selector">
              {currentBank === item.id ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20px"
                  height="20px"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#f95959"
                    d="m10.6 13.8l-2.15-2.15q-.275-.275-.7-.275t-.7.275t-.275.7t.275.7L9.9 15.9q.3.3.7.3t.7-.3l5.65-5.65q.275-.275.275-.7t-.275-.7t-.7-.275t-.7.275zM12 22q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22"
                  ></path>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20px"
                  height="20px"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#768096"
                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2m0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8s8 3.58 8 8s-3.58 8-8 8"
                  ></path>
                </svg>
              )}
              <span>Select</span>
            </div>
          </div>
        ))}
      </div>
      <div className="bankadd-container">
        <Link to={"/wallet/AddBank"}>
          <div className="bankadd-btn">
            <img src={bankAddIcon} alt="Add Bank" />
            <span>Add a bank account number</span>
          </div>
        </Link>
      </div>
      <Toast
        message={toast.message}
        isVisible={toast.isVisible}
        onClose={() => setToast({ isVisible: false, message: "" })}
      />
    </>
  );
};

export default BankAccount;
