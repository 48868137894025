import React from "react";
import { Link } from "react-router-dom";
import minigame1 from "../../../assets/img/mini_game.jpeg";
import {
  fishing1,
  popular1,
  popular2,
  popular3,
  popular4,
  popular5,
  popular6,
  popular7,
  popular8,
  slots10,
  slots2,
  slots7,
} from "../../../assets/media";

const Popular = ({ viewall, callGame }) => (
  <div className="d-flex flex-col align-center">
    <div className="popular-heading d-flex align-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20px"
        height="20px"
        viewBox="0 0 24 24"
      >
        <path
          fill="#f95959"
          d="m10.833 8.142l1.8-3.57a1.64 1.64 0 0 1 1.49-.92c.306 0 .606.09.86.26c.251.166.452.398.58.67l1.76 3.57l.11.08l3.92.57c.302.04.586.165.82.36c.234.205.41.467.51.76a1.66 1.66 0 0 1 0 .91a1.57 1.57 0 0 1-.44.77l-2.84 2.77a.11.11 0 0 0 0 .11l.68 3.93c.047.297.016.6-.09.88a1.7 1.7 0 0 1-1.4 1.05a1.59 1.59 0 0 1-.91-.2l-3.38-1.77l-.17-.07h-.14l-3.52 1.84a1.61 1.61 0 0 1-.76.19h-.17a1.7 1.7 0 0 1-.84-.32a1.54 1.54 0 0 1-.55-.71a1.61 1.61 0 0 1 0-1l.66-3.81a.491.491 0 0 0 0-.11h-.05l-2.82-2.74a1.69 1.69 0 0 1-.46-.8a1.62 1.62 0 0 1 .53-1.65a1.59 1.59 0 0 1 .83-.36l3.87-.57zm-2.83-2h-6a.75.75 0 0 1 0-1.5h6a.75.75 0 1 1 0 1.5m-3 12.07h-3a.75.75 0 1 1 0-1.5h3a.75.75 0 1 1 0 1.5m-1.46-5.77h-1.5a.75.75 0 1 1 0-1.5h1.5a.75.75 0 1 1 0 1.5"
        />
      </svg>
      <span>Platform recommendation</span>
    </div>
    <div className="popular-box">
      <div
        className="popular-box-main-container"
        style={!viewall ? { height: "140px" } : {}}
        onClick={callGame}
      >
        <div
          className="popular-box-container"
          style={!viewall ? { height: "115px" } : {}}
        >
          <img src={minigame1} alt="" />
          <svg
            data-v-d06787cb=""
            width="20px"
            height="20px"
            viewBox="0 0 40 37"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="hot_bage"
          >
            <path
              d="M8.00798 5.79803C3.77459 9.42666 1.96027 13.3578 1.20431 15.6256C0.0703725 19.0272 0.328033 26.4667 5.36212 31.5009C12.5438 38.6826 22.3713 36.0367 26.1511 33.3907C31.144 29.8955 39.3805 18.6494 34.4667 23.1852C32.8472 24.6801 31.6436 24.1183 32.9548 22.8072C34.2659 21.4961 35.2227 20.9173 36.7346 18.6494C38.7544 15.6198 38.8765 12.0977 38.2465 10.3338C37.8685 10.8378 36.5834 12.1481 34.4667 13.3577C32.35 14.5672 30.3089 13.3577 29.5529 12.6017C30.3089 12.6017 32.9548 12.0725 35.9786 8.4439C39.7584 3.90812 39.3805 0.128418 39.3805 0.128418C39.3805 0.128418 37.4906 2.01822 33.3328 3.15216C29.175 4.28611 26.9071 2.77429 22.3713 2.77429C17.8355 2.77429 14.8116 5.79803 15.1896 4.28611C15.492 3.07656 17.5835 1.51424 18.5915 0.884272C16.8276 1.01027 12.2414 2.16941 8.00798 5.79803Z"
              fill="url(#paint0_linear_597_39012)"
            ></path>
            <path
              d="M3.8208 25V15.5455H6.38756V19.2386H9.78529V15.5455H12.3521V25H9.78529V21.3068H6.38756V25H3.8208ZM22.7299 20.2727C22.7299 21.3253 22.5252 22.2132 22.1159 22.9364C21.7066 23.6566 21.1541 24.2029 20.4586 24.5753C19.763 24.9446 18.9875 25.1293 18.1319 25.1293C17.2701 25.1293 16.4915 24.9431 15.7959 24.5707C15.1035 24.1952 14.5526 23.6474 14.1432 22.9272C13.737 22.204 13.5339 21.3191 13.5339 20.2727C13.5339 19.2202 13.737 18.3338 14.1432 17.6136C14.5526 16.8904 15.1035 16.3441 15.7959 15.9748C16.4915 15.6024 17.2701 15.4162 18.1319 15.4162C18.9875 15.4162 19.763 15.6024 20.4586 15.9748C21.1541 16.3441 21.7066 16.8904 22.1159 17.6136C22.5252 18.3338 22.7299 19.2202 22.7299 20.2727ZM20.0893 20.2727C20.0893 19.7064 20.0139 19.2294 19.8631 18.8416C19.7153 18.4508 19.4953 18.1553 19.2029 17.9553C18.9136 17.7521 18.5566 17.6506 18.1319 17.6506C17.7072 17.6506 17.3486 17.7521 17.0562 17.9553C16.7669 18.1553 16.5469 18.4508 16.3961 18.8416C16.2484 19.2294 16.1745 19.7064 16.1745 20.2727C16.1745 20.839 16.2484 21.3176 16.3961 21.7085C16.5469 22.0962 16.7669 22.3917 17.0562 22.5948C17.3486 22.7949 17.7072 22.8949 18.1319 22.8949C18.5566 22.8949 18.9136 22.7949 19.2029 22.5948C19.4953 22.3917 19.7153 22.0962 19.8631 21.7085C20.0139 21.3176 20.0893 20.839 20.0893 20.2727ZM23.2146 17.6136V15.5455H31.432V17.6136H28.5882V25H26.0584V17.6136H23.2146Z"
              fill="url(#paint1_linear_597_39012)"
            ></path>
            <defs>
              <linearGradient
                id="paint0_linear_597_39012"
                x1="20.0209"
                y1="0.128418"
                x2="20.0209"
                y2="36.1366"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#FE6868"></stop>
                <stop offset="1" stopColor="#CF0405"></stop>
              </linearGradient>
              <linearGradient
                id="paint1_linear_597_39012"
                x1="17.5"
                y1="13"
                x2="17.5"
                y2="27"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="white"></stop>
                <stop offset="1" stopColor="#FFE081"></stop>
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div className="popular-progress-container">
          <span className="popular-progress"></span>
          <span className="popular-text">odds of 87.6%</span>
        </div>
      </div>
      <div
        className="popular-box-main-container"
        style={!viewall ? { height: "140px" } : {}}
        onClick={callGame}
      >
        <div
          className="popular-box-container"
          style={!viewall ? { height: "115px" } : {}}
        >
          <img src={popular1} alt="" />
          <svg
            data-v-d06787cb=""
            width="20px"
            height="20px"
            viewBox="0 0 40 37"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="hot_bage"
          >
            <path
              d="M8.00798 5.79803C3.77459 9.42666 1.96027 13.3578 1.20431 15.6256C0.0703725 19.0272 0.328033 26.4667 5.36212 31.5009C12.5438 38.6826 22.3713 36.0367 26.1511 33.3907C31.144 29.8955 39.3805 18.6494 34.4667 23.1852C32.8472 24.6801 31.6436 24.1183 32.9548 22.8072C34.2659 21.4961 35.2227 20.9173 36.7346 18.6494C38.7544 15.6198 38.8765 12.0977 38.2465 10.3338C37.8685 10.8378 36.5834 12.1481 34.4667 13.3577C32.35 14.5672 30.3089 13.3577 29.5529 12.6017C30.3089 12.6017 32.9548 12.0725 35.9786 8.4439C39.7584 3.90812 39.3805 0.128418 39.3805 0.128418C39.3805 0.128418 37.4906 2.01822 33.3328 3.15216C29.175 4.28611 26.9071 2.77429 22.3713 2.77429C17.8355 2.77429 14.8116 5.79803 15.1896 4.28611C15.492 3.07656 17.5835 1.51424 18.5915 0.884272C16.8276 1.01027 12.2414 2.16941 8.00798 5.79803Z"
              fill="url(#paint0_linear_597_39012)"
            ></path>
            <path
              d="M3.8208 25V15.5455H6.38756V19.2386H9.78529V15.5455H12.3521V25H9.78529V21.3068H6.38756V25H3.8208ZM22.7299 20.2727C22.7299 21.3253 22.5252 22.2132 22.1159 22.9364C21.7066 23.6566 21.1541 24.2029 20.4586 24.5753C19.763 24.9446 18.9875 25.1293 18.1319 25.1293C17.2701 25.1293 16.4915 24.9431 15.7959 24.5707C15.1035 24.1952 14.5526 23.6474 14.1432 22.9272C13.737 22.204 13.5339 21.3191 13.5339 20.2727C13.5339 19.2202 13.737 18.3338 14.1432 17.6136C14.5526 16.8904 15.1035 16.3441 15.7959 15.9748C16.4915 15.6024 17.2701 15.4162 18.1319 15.4162C18.9875 15.4162 19.763 15.6024 20.4586 15.9748C21.1541 16.3441 21.7066 16.8904 22.1159 17.6136C22.5252 18.3338 22.7299 19.2202 22.7299 20.2727ZM20.0893 20.2727C20.0893 19.7064 20.0139 19.2294 19.8631 18.8416C19.7153 18.4508 19.4953 18.1553 19.2029 17.9553C18.9136 17.7521 18.5566 17.6506 18.1319 17.6506C17.7072 17.6506 17.3486 17.7521 17.0562 17.9553C16.7669 18.1553 16.5469 18.4508 16.3961 18.8416C16.2484 19.2294 16.1745 19.7064 16.1745 20.2727C16.1745 20.839 16.2484 21.3176 16.3961 21.7085C16.5469 22.0962 16.7669 22.3917 17.0562 22.5948C17.3486 22.7949 17.7072 22.8949 18.1319 22.8949C18.5566 22.8949 18.9136 22.7949 19.2029 22.5948C19.4953 22.3917 19.7153 22.0962 19.8631 21.7085C20.0139 21.3176 20.0893 20.839 20.0893 20.2727ZM23.2146 17.6136V15.5455H31.432V17.6136H28.5882V25H26.0584V17.6136H23.2146Z"
              fill="url(#paint1_linear_597_39012)"
            ></path>
            <defs>
              <linearGradient
                id="paint0_linear_597_39012"
                x1="20.0209"
                y1="0.128418"
                x2="20.0209"
                y2="36.1366"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#FE6868"></stop>
                <stop offset="1" stopColor="#CF0405"></stop>
              </linearGradient>
              <linearGradient
                id="paint1_linear_597_39012"
                x1="17.5"
                y1="13"
                x2="17.5"
                y2="27"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="white"></stop>
                <stop offset="1" stopColor="#FFE081"></stop>
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div className="popular-progress-container">
          <span className="popular-progress"></span>
          <span className="popular-text">odds of 87.6%</span>
        </div>
      </div>
      <div
        className="popular-box-main-container"
        style={!viewall ? { height: "140px" } : {}}
        onClick={callGame}
      >
        <div
          className="popular-box-container"
          style={!viewall ? { height: "115px" } : {}}
        >
          <img src={slots10} alt="" />
          <svg
            data-v-d06787cb=""
            width="20px"
            height="20px"
            viewBox="0 0 40 37"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="hot_bage"
          >
            <path
              d="M8.00798 5.79803C3.77459 9.42666 1.96027 13.3578 1.20431 15.6256C0.0703725 19.0272 0.328033 26.4667 5.36212 31.5009C12.5438 38.6826 22.3713 36.0367 26.1511 33.3907C31.144 29.8955 39.3805 18.6494 34.4667 23.1852C32.8472 24.6801 31.6436 24.1183 32.9548 22.8072C34.2659 21.4961 35.2227 20.9173 36.7346 18.6494C38.7544 15.6198 38.8765 12.0977 38.2465 10.3338C37.8685 10.8378 36.5834 12.1481 34.4667 13.3577C32.35 14.5672 30.3089 13.3577 29.5529 12.6017C30.3089 12.6017 32.9548 12.0725 35.9786 8.4439C39.7584 3.90812 39.3805 0.128418 39.3805 0.128418C39.3805 0.128418 37.4906 2.01822 33.3328 3.15216C29.175 4.28611 26.9071 2.77429 22.3713 2.77429C17.8355 2.77429 14.8116 5.79803 15.1896 4.28611C15.492 3.07656 17.5835 1.51424 18.5915 0.884272C16.8276 1.01027 12.2414 2.16941 8.00798 5.79803Z"
              fill="url(#paint0_linear_597_39012)"
            ></path>
            <path
              d="M3.8208 25V15.5455H6.38756V19.2386H9.78529V15.5455H12.3521V25H9.78529V21.3068H6.38756V25H3.8208ZM22.7299 20.2727C22.7299 21.3253 22.5252 22.2132 22.1159 22.9364C21.7066 23.6566 21.1541 24.2029 20.4586 24.5753C19.763 24.9446 18.9875 25.1293 18.1319 25.1293C17.2701 25.1293 16.4915 24.9431 15.7959 24.5707C15.1035 24.1952 14.5526 23.6474 14.1432 22.9272C13.737 22.204 13.5339 21.3191 13.5339 20.2727C13.5339 19.2202 13.737 18.3338 14.1432 17.6136C14.5526 16.8904 15.1035 16.3441 15.7959 15.9748C16.4915 15.6024 17.2701 15.4162 18.1319 15.4162C18.9875 15.4162 19.763 15.6024 20.4586 15.9748C21.1541 16.3441 21.7066 16.8904 22.1159 17.6136C22.5252 18.3338 22.7299 19.2202 22.7299 20.2727ZM20.0893 20.2727C20.0893 19.7064 20.0139 19.2294 19.8631 18.8416C19.7153 18.4508 19.4953 18.1553 19.2029 17.9553C18.9136 17.7521 18.5566 17.6506 18.1319 17.6506C17.7072 17.6506 17.3486 17.7521 17.0562 17.9553C16.7669 18.1553 16.5469 18.4508 16.3961 18.8416C16.2484 19.2294 16.1745 19.7064 16.1745 20.2727C16.1745 20.839 16.2484 21.3176 16.3961 21.7085C16.5469 22.0962 16.7669 22.3917 17.0562 22.5948C17.3486 22.7949 17.7072 22.8949 18.1319 22.8949C18.5566 22.8949 18.9136 22.7949 19.2029 22.5948C19.4953 22.3917 19.7153 22.0962 19.8631 21.7085C20.0139 21.3176 20.0893 20.839 20.0893 20.2727ZM23.2146 17.6136V15.5455H31.432V17.6136H28.5882V25H26.0584V17.6136H23.2146Z"
              fill="url(#paint1_linear_597_39012)"
            ></path>
            <defs>
              <linearGradient
                id="paint0_linear_597_39012"
                x1="20.0209"
                y1="0.128418"
                x2="20.0209"
                y2="36.1366"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#FE6868"></stop>
                <stop offset="1" stopColor="#CF0405"></stop>
              </linearGradient>
              <linearGradient
                id="paint1_linear_597_39012"
                x1="17.5"
                y1="13"
                x2="17.5"
                y2="27"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="white"></stop>
                <stop offset="1" stopColor="#FFE081"></stop>
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div className="popular-progress-container">
          <span className="popular-progress"></span>
          <span className="popular-text">odds of 87.6%</span>
        </div>
      </div>
      <div
        className="popular-box-main-container"
        style={!viewall ? { height: "140px" } : {}}
        onClick={callGame}
      >
        <div
          className="popular-box-container"
          style={!viewall ? { height: "115px" } : {}}
        >
          <img src={slots2} alt="" />
          <svg
            data-v-d06787cb=""
            width="20px"
            height="20px"
            viewBox="0 0 40 37"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="hot_bage"
          >
            <path
              d="M8.00798 5.79803C3.77459 9.42666 1.96027 13.3578 1.20431 15.6256C0.0703725 19.0272 0.328033 26.4667 5.36212 31.5009C12.5438 38.6826 22.3713 36.0367 26.1511 33.3907C31.144 29.8955 39.3805 18.6494 34.4667 23.1852C32.8472 24.6801 31.6436 24.1183 32.9548 22.8072C34.2659 21.4961 35.2227 20.9173 36.7346 18.6494C38.7544 15.6198 38.8765 12.0977 38.2465 10.3338C37.8685 10.8378 36.5834 12.1481 34.4667 13.3577C32.35 14.5672 30.3089 13.3577 29.5529 12.6017C30.3089 12.6017 32.9548 12.0725 35.9786 8.4439C39.7584 3.90812 39.3805 0.128418 39.3805 0.128418C39.3805 0.128418 37.4906 2.01822 33.3328 3.15216C29.175 4.28611 26.9071 2.77429 22.3713 2.77429C17.8355 2.77429 14.8116 5.79803 15.1896 4.28611C15.492 3.07656 17.5835 1.51424 18.5915 0.884272C16.8276 1.01027 12.2414 2.16941 8.00798 5.79803Z"
              fill="url(#paint0_linear_597_39012)"
            ></path>
            <path
              d="M3.8208 25V15.5455H6.38756V19.2386H9.78529V15.5455H12.3521V25H9.78529V21.3068H6.38756V25H3.8208ZM22.7299 20.2727C22.7299 21.3253 22.5252 22.2132 22.1159 22.9364C21.7066 23.6566 21.1541 24.2029 20.4586 24.5753C19.763 24.9446 18.9875 25.1293 18.1319 25.1293C17.2701 25.1293 16.4915 24.9431 15.7959 24.5707C15.1035 24.1952 14.5526 23.6474 14.1432 22.9272C13.737 22.204 13.5339 21.3191 13.5339 20.2727C13.5339 19.2202 13.737 18.3338 14.1432 17.6136C14.5526 16.8904 15.1035 16.3441 15.7959 15.9748C16.4915 15.6024 17.2701 15.4162 18.1319 15.4162C18.9875 15.4162 19.763 15.6024 20.4586 15.9748C21.1541 16.3441 21.7066 16.8904 22.1159 17.6136C22.5252 18.3338 22.7299 19.2202 22.7299 20.2727ZM20.0893 20.2727C20.0893 19.7064 20.0139 19.2294 19.8631 18.8416C19.7153 18.4508 19.4953 18.1553 19.2029 17.9553C18.9136 17.7521 18.5566 17.6506 18.1319 17.6506C17.7072 17.6506 17.3486 17.7521 17.0562 17.9553C16.7669 18.1553 16.5469 18.4508 16.3961 18.8416C16.2484 19.2294 16.1745 19.7064 16.1745 20.2727C16.1745 20.839 16.2484 21.3176 16.3961 21.7085C16.5469 22.0962 16.7669 22.3917 17.0562 22.5948C17.3486 22.7949 17.7072 22.8949 18.1319 22.8949C18.5566 22.8949 18.9136 22.7949 19.2029 22.5948C19.4953 22.3917 19.7153 22.0962 19.8631 21.7085C20.0139 21.3176 20.0893 20.839 20.0893 20.2727ZM23.2146 17.6136V15.5455H31.432V17.6136H28.5882V25H26.0584V17.6136H23.2146Z"
              fill="url(#paint1_linear_597_39012)"
            ></path>
            <defs>
              <linearGradient
                id="paint0_linear_597_39012"
                x1="20.0209"
                y1="0.128418"
                x2="20.0209"
                y2="36.1366"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#FE6868"></stop>
                <stop offset="1" stopColor="#CF0405"></stop>
              </linearGradient>
              <linearGradient
                id="paint1_linear_597_39012"
                x1="17.5"
                y1="13"
                x2="17.5"
                y2="27"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="white"></stop>
                <stop offset="1" stopColor="#FFE081"></stop>
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div className="popular-progress-container">
          <span className="popular-progress"></span>
          <span className="popular-text">odds of 87.6%</span>
        </div>
      </div>
      <div
        className="popular-box-main-container"
        style={!viewall ? { height: "140px" } : {}}
        onClick={callGame}
      >
        <div
          className="popular-box-container"
          style={!viewall ? { height: "115px" } : {}}
        >
          <img src={popular4} alt="" />
          <svg
            data-v-d06787cb=""
            width="20px"
            height="20px"
            viewBox="0 0 40 37"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="hot_bage"
          >
            <path
              d="M8.00798 5.79803C3.77459 9.42666 1.96027 13.3578 1.20431 15.6256C0.0703725 19.0272 0.328033 26.4667 5.36212 31.5009C12.5438 38.6826 22.3713 36.0367 26.1511 33.3907C31.144 29.8955 39.3805 18.6494 34.4667 23.1852C32.8472 24.6801 31.6436 24.1183 32.9548 22.8072C34.2659 21.4961 35.2227 20.9173 36.7346 18.6494C38.7544 15.6198 38.8765 12.0977 38.2465 10.3338C37.8685 10.8378 36.5834 12.1481 34.4667 13.3577C32.35 14.5672 30.3089 13.3577 29.5529 12.6017C30.3089 12.6017 32.9548 12.0725 35.9786 8.4439C39.7584 3.90812 39.3805 0.128418 39.3805 0.128418C39.3805 0.128418 37.4906 2.01822 33.3328 3.15216C29.175 4.28611 26.9071 2.77429 22.3713 2.77429C17.8355 2.77429 14.8116 5.79803 15.1896 4.28611C15.492 3.07656 17.5835 1.51424 18.5915 0.884272C16.8276 1.01027 12.2414 2.16941 8.00798 5.79803Z"
              fill="url(#paint0_linear_597_39012)"
            ></path>
            <path
              d="M3.8208 25V15.5455H6.38756V19.2386H9.78529V15.5455H12.3521V25H9.78529V21.3068H6.38756V25H3.8208ZM22.7299 20.2727C22.7299 21.3253 22.5252 22.2132 22.1159 22.9364C21.7066 23.6566 21.1541 24.2029 20.4586 24.5753C19.763 24.9446 18.9875 25.1293 18.1319 25.1293C17.2701 25.1293 16.4915 24.9431 15.7959 24.5707C15.1035 24.1952 14.5526 23.6474 14.1432 22.9272C13.737 22.204 13.5339 21.3191 13.5339 20.2727C13.5339 19.2202 13.737 18.3338 14.1432 17.6136C14.5526 16.8904 15.1035 16.3441 15.7959 15.9748C16.4915 15.6024 17.2701 15.4162 18.1319 15.4162C18.9875 15.4162 19.763 15.6024 20.4586 15.9748C21.1541 16.3441 21.7066 16.8904 22.1159 17.6136C22.5252 18.3338 22.7299 19.2202 22.7299 20.2727ZM20.0893 20.2727C20.0893 19.7064 20.0139 19.2294 19.8631 18.8416C19.7153 18.4508 19.4953 18.1553 19.2029 17.9553C18.9136 17.7521 18.5566 17.6506 18.1319 17.6506C17.7072 17.6506 17.3486 17.7521 17.0562 17.9553C16.7669 18.1553 16.5469 18.4508 16.3961 18.8416C16.2484 19.2294 16.1745 19.7064 16.1745 20.2727C16.1745 20.839 16.2484 21.3176 16.3961 21.7085C16.5469 22.0962 16.7669 22.3917 17.0562 22.5948C17.3486 22.7949 17.7072 22.8949 18.1319 22.8949C18.5566 22.8949 18.9136 22.7949 19.2029 22.5948C19.4953 22.3917 19.7153 22.0962 19.8631 21.7085C20.0139 21.3176 20.0893 20.839 20.0893 20.2727ZM23.2146 17.6136V15.5455H31.432V17.6136H28.5882V25H26.0584V17.6136H23.2146Z"
              fill="url(#paint1_linear_597_39012)"
            ></path>
            <defs>
              <linearGradient
                id="paint0_linear_597_39012"
                x1="20.0209"
                y1="0.128418"
                x2="20.0209"
                y2="36.1366"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#FE6868"></stop>
                <stop offset="1" stopColor="#CF0405"></stop>
              </linearGradient>
              <linearGradient
                id="paint1_linear_597_39012"
                x1="17.5"
                y1="13"
                x2="17.5"
                y2="27"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="white"></stop>
                <stop offset="1" stopColor="#FFE081"></stop>
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div className="popular-progress-container">
          <span className="popular-progress"></span>
          <span className="popular-text">odds of 87.6%</span>
        </div>
      </div>
      <div
        className="popular-box-main-container"
        style={!viewall ? { height: "140px" } : {}}
        onClick={callGame}
      >
        <div
          className="popular-box-container"
          style={!viewall ? { height: "115px" } : {}}
        >
          <img src={slots7} alt="" />
          <svg
            data-v-d06787cb=""
            width="20px"
            height="20px"
            viewBox="0 0 40 37"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="hot_bage"
          >
            <path
              d="M8.00798 5.79803C3.77459 9.42666 1.96027 13.3578 1.20431 15.6256C0.0703725 19.0272 0.328033 26.4667 5.36212 31.5009C12.5438 38.6826 22.3713 36.0367 26.1511 33.3907C31.144 29.8955 39.3805 18.6494 34.4667 23.1852C32.8472 24.6801 31.6436 24.1183 32.9548 22.8072C34.2659 21.4961 35.2227 20.9173 36.7346 18.6494C38.7544 15.6198 38.8765 12.0977 38.2465 10.3338C37.8685 10.8378 36.5834 12.1481 34.4667 13.3577C32.35 14.5672 30.3089 13.3577 29.5529 12.6017C30.3089 12.6017 32.9548 12.0725 35.9786 8.4439C39.7584 3.90812 39.3805 0.128418 39.3805 0.128418C39.3805 0.128418 37.4906 2.01822 33.3328 3.15216C29.175 4.28611 26.9071 2.77429 22.3713 2.77429C17.8355 2.77429 14.8116 5.79803 15.1896 4.28611C15.492 3.07656 17.5835 1.51424 18.5915 0.884272C16.8276 1.01027 12.2414 2.16941 8.00798 5.79803Z"
              fill="url(#paint0_linear_597_39012)"
            ></path>
            <path
              d="M3.8208 25V15.5455H6.38756V19.2386H9.78529V15.5455H12.3521V25H9.78529V21.3068H6.38756V25H3.8208ZM22.7299 20.2727C22.7299 21.3253 22.5252 22.2132 22.1159 22.9364C21.7066 23.6566 21.1541 24.2029 20.4586 24.5753C19.763 24.9446 18.9875 25.1293 18.1319 25.1293C17.2701 25.1293 16.4915 24.9431 15.7959 24.5707C15.1035 24.1952 14.5526 23.6474 14.1432 22.9272C13.737 22.204 13.5339 21.3191 13.5339 20.2727C13.5339 19.2202 13.737 18.3338 14.1432 17.6136C14.5526 16.8904 15.1035 16.3441 15.7959 15.9748C16.4915 15.6024 17.2701 15.4162 18.1319 15.4162C18.9875 15.4162 19.763 15.6024 20.4586 15.9748C21.1541 16.3441 21.7066 16.8904 22.1159 17.6136C22.5252 18.3338 22.7299 19.2202 22.7299 20.2727ZM20.0893 20.2727C20.0893 19.7064 20.0139 19.2294 19.8631 18.8416C19.7153 18.4508 19.4953 18.1553 19.2029 17.9553C18.9136 17.7521 18.5566 17.6506 18.1319 17.6506C17.7072 17.6506 17.3486 17.7521 17.0562 17.9553C16.7669 18.1553 16.5469 18.4508 16.3961 18.8416C16.2484 19.2294 16.1745 19.7064 16.1745 20.2727C16.1745 20.839 16.2484 21.3176 16.3961 21.7085C16.5469 22.0962 16.7669 22.3917 17.0562 22.5948C17.3486 22.7949 17.7072 22.8949 18.1319 22.8949C18.5566 22.8949 18.9136 22.7949 19.2029 22.5948C19.4953 22.3917 19.7153 22.0962 19.8631 21.7085C20.0139 21.3176 20.0893 20.839 20.0893 20.2727ZM23.2146 17.6136V15.5455H31.432V17.6136H28.5882V25H26.0584V17.6136H23.2146Z"
              fill="url(#paint1_linear_597_39012)"
            ></path>
            <defs>
              <linearGradient
                id="paint0_linear_597_39012"
                x1="20.0209"
                y1="0.128418"
                x2="20.0209"
                y2="36.1366"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#FE6868"></stop>
                <stop offset="1" stopColor="#CF0405"></stop>
              </linearGradient>
              <linearGradient
                id="paint1_linear_597_39012"
                x1="17.5"
                y1="13"
                x2="17.5"
                y2="27"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="white"></stop>
                <stop offset="1" stopColor="#FFE081"></stop>
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div className="popular-progress-container">
          <span className="popular-progress"></span>
          <span className="popular-text">odds of 87.6%</span>
        </div>
      </div>
    </div>
    <div className="mt-2"></div>
    <div className="popular-heading d-flex align-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20px"
        height="20px"
        viewBox="0 0 24 24"
      >
        <path
          fill="#f95959"
          d="m10.833 8.142l1.8-3.57a1.64 1.64 0 0 1 1.49-.92c.306 0 .606.09.86.26c.251.166.452.398.58.67l1.76 3.57l.11.08l3.92.57c.302.04.586.165.82.36c.234.205.41.467.51.76a1.66 1.66 0 0 1 0 .91a1.57 1.57 0 0 1-.44.77l-2.84 2.77a.11.11 0 0 0 0 .11l.68 3.93c.047.297.016.6-.09.88a1.7 1.7 0 0 1-1.4 1.05a1.59 1.59 0 0 1-.91-.2l-3.38-1.77l-.17-.07h-.14l-3.52 1.84a1.61 1.61 0 0 1-.76.19h-.17a1.7 1.7 0 0 1-.84-.32a1.54 1.54 0 0 1-.55-.71a1.61 1.61 0 0 1 0-1l.66-3.81a.491.491 0 0 0 0-.11h-.05l-2.82-2.74a1.69 1.69 0 0 1-.46-.8a1.62 1.62 0 0 1 .53-1.65a1.59 1.59 0 0 1 .83-.36l3.87-.57zm-2.83-2h-6a.75.75 0 0 1 0-1.5h6a.75.75 0 1 1 0 1.5m-3 12.07h-3a.75.75 0 1 1 0-1.5h3a.75.75 0 1 1 0 1.5m-1.46-5.77h-1.5a.75.75 0 1 1 0-1.5h1.5a.75.75 0 1 1 0 1.5"
        />
      </svg>
      <span>Popular</span>
    </div>
    <div className="popular-box">
      <div
        className="popular-box-main-container"
        style={!viewall ? { height: "140px" } : {}}
        onClick={callGame}
      >
        <div
          className="popular-box-container"
          style={!viewall ? { height: "115px" } : {}}
        >
          <img src={popular5} alt="" />
        </div>
        <div className="popular-progress-container">
          <span className="popular-progress"></span>
          <span className="popular-text">odds of 87.6%</span>
        </div>
      </div>
      <div
        className="popular-box-main-container"
        style={!viewall ? { height: "140px" } : {}}
        onClick={callGame}
      >
        <div
          className="popular-box-container"
          style={!viewall ? { height: "115px" } : {}}
        >
          <img src={popular1} alt="" />
        </div>
        <div className="popular-progress-container">
          <span className="popular-progress"></span>
          <span className="popular-text">odds of 87.6%</span>
        </div>
      </div>
      <div
        className="popular-box-main-container"
        style={!viewall ? { height: "140px" } : {}}
        onClick={callGame}
      >
        <div
          className="popular-box-container"
          style={!viewall ? { height: "115px" } : {}}
        >
          <img src={popular2} alt="" />
        </div>
        <div className="popular-progress-container">
          <span className="popular-progress"></span>
          <span className="popular-text">odds of 87.6%</span>
        </div>
      </div>
      <div
        className="popular-box-main-container"
        style={!viewall ? { height: "140px" } : {}}
        onClick={callGame}
      >
        <div
          className="popular-box-container"
          style={!viewall ? { height: "115px" } : {}}
        >
          <img src={popular3} alt="" />
        </div>
        <div className="popular-progress-container">
          <span className="popular-progress"></span>
          <span className="popular-text">odds of 87.6%</span>
        </div>
      </div>
      <div
        className="popular-box-main-container"
        style={!viewall ? { height: "140px" } : {}}
        onClick={callGame}
      >
        <div
          className="popular-box-container"
          style={!viewall ? { height: "115px" } : {}}
        >
          <img src={popular4} alt="" />
        </div>
        <div className="popular-progress-container">
          <span className="popular-progress"></span>
          <span className="popular-text">odds of 87.6%</span>
        </div>
      </div>
      <div
        className="popular-box-main-container"
        style={!viewall ? { height: "140px" } : {}}
        onClick={callGame}
      >
        <div
          className="popular-box-container"
          style={!viewall ? { height: "115px" } : {}}
        >
          <img src={popular6} alt="" />
        </div>
        <div className="popular-progress-container">
          <span className="popular-progress"></span>
          <span className="popular-text">odds of 87.6%</span>
        </div>
      </div>
      <div
        className="popular-box-main-container"
        style={!viewall ? { height: "140px" } : {}}
        onClick={callGame}
      >
        <div
          className="popular-box-container"
          style={!viewall ? { height: "115px" } : {}}
        >
          <img src={popular7} alt="" />
        </div>
        <div className="popular-progress-container">
          <span className="popular-progress"></span>
          <span className="popular-text">odds of 87.6%</span>
        </div>
      </div>
      <div
        className="popular-box-main-container"
        style={!viewall ? { height: "140px" } : {}}
        onClick={callGame}
      >
        <div
          className="popular-box-container"
          style={!viewall ? { height: "115px" } : {}}
        >
          <img src={popular8} alt="" />
        </div>
        <div className="popular-progress-container">
          <span className="popular-progress"></span>
          <span className="popular-text">odds of 87.6%</span>
        </div>
      </div>
      <div
        className="popular-box-main-container"
        style={!viewall ? { height: "140px" } : {}}
        onClick={callGame}
      >
        <div
          className="popular-box-container"
          style={!viewall ? { height: "115px" } : {}}
        >
          <img src={fishing1} alt="" />
        </div>
        <div className="popular-progress-container">
          <span className="popular-progress"></span>
          <span className="popular-text">odds of 87.6%</span>
        </div>
      </div>
    </div>
    {viewall ? (
      <Link to="/home/AllGames">
        <div className="viewallbtn">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25px"
            height="25px"
            viewBox="0 0 24 24"
          >
            <path
              fill="#f95959"
              d="M10.995 4.68v3.88A2.44 2.44 0 0 1 8.545 11h-3.86a2.38 2.38 0 0 1-1.72-.72a2.41 2.41 0 0 1-.71-1.72V4.69a2.44 2.44 0 0 1 2.43-2.44h3.87a2.42 2.42 0 0 1 1.72.72a2.39 2.39 0 0 1 .72 1.71m10.75.01v3.87a2.46 2.46 0 0 1-2.43 2.44h-3.88a2.5 2.5 0 0 1-1.73-.71a2.44 2.44 0 0 1-.71-1.73V4.69a2.39 2.39 0 0 1 .72-1.72a2.42 2.42 0 0 1 1.72-.72h3.87a2.46 2.46 0 0 1 2.44 2.44m0 10.75v3.87a2.46 2.46 0 0 1-2.43 2.44h-3.88a2.5 2.5 0 0 1-1.75-.69a2.42 2.42 0 0 1-.71-1.73v-3.87a2.391 2.391 0 0 1 .72-1.72a2.421 2.421 0 0 1 1.72-.72h3.87a2.46 2.46 0 0 1 2.44 2.44zm-10.75.01v3.87a2.46 2.46 0 0 1-2.45 2.43h-3.86a2.42 2.42 0 0 1-2.43-2.43v-3.87A2.46 2.46 0 0 1 4.685 13h3.87a2.49 2.49 0 0 1 1.73.72a2.45 2.45 0 0 1 .71 1.73"
            />
          </svg>

          <span>View All</span>
        </div>
      </Link>
    ) : (
      ""
    )}
  </div>
);

export default Popular;
