import React from "react";
import { Link } from "react-router-dom";
import "../../assets/css/main.css";
import brandLogo from "../../assets/logo/brand_logo.png";
import En from "../../assets/icons/en.png"

const LoginHeader = ({ path,setOpenPopup, heading }) => {

  return (
    <div className="login-header">
      <div className="header-heading">
        <img src={brandLogo} alt="" />
      </div>
      <div className="header-back">
        <Link to={path}>
          <div className="link-container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20px"
              height="20px"
              viewBox="0 0 24 24"
            >
              <path
                fill="#fff"
                d="m3.55 12l7.35 7.35q.375.375.363.875t-.388.875t-.875.375t-.875-.375l-7.7-7.675q-.3-.3-.45-.675T.825 12t.15-.75t.45-.675l7.7-7.7q.375-.375.888-.363t.887.388t.375.875t-.375.875z"
              />
            </svg>
          </div>
        </Link>
      </div>
<div className="header-icon" onClick={()=>setOpenPopup(true)}>
  <img src={En} alt="" />
  <span>EN</span>
</div>

    </div>
  );
};

export default LoginHeader;
