import React, { useEffect, useState } from "react";
import GameHeader from "../../common/GameHeader";
import "../../../assets/css/wingo.css";
import { Link, useNavigate } from "react-router-dom";
import wingoTabLogo from "../../../assets/logo/wingotablogo.png";
import wingoTabLogoActive from "../../../assets/logo/wingotablogoactive.png";

import walletsvg from "../../../assets/icons/walletsvg.svg";

import Wingo1 from "./wingo/Wingo1";
import Wingo3 from "./wingo/Wingo3";
import Wingo5 from "./wingo/Wingo5";
import Wingo10 from "./wingo/Wingo10";
import { getUser } from "../../../utils/UserController";
import WinStatus from "../model/WinStatus";
import { formatMoney, handleSpeak } from "../../../utils/Utils";
import Toast from "../Toast";
import Dragon from "../../components/Dragon";
  

const Wingo = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("30s");
  const [user, setUser] = useState({});
  const [modelData, setModelData] = useState({});
  const [change, setChange] = useState(true);
    const [text, setText] = useState("Due to ongoing issues with the UPI payment system across all banks in India, you may");


  const texts = [
  "Due to ongoing issues with the UPI payment system across all banks in India, you may",
  "in3win Official Website To visit our official website, be sure to use the link below",
  "Stay alert for fraudulent sites that look like our in3win official page, refrain from",
  "Also, be aware that when transferring money, you may face delays or failures due to an",
  "The most fun online lottery platform! We are happy to have you join us. As a new member",
  "All Recharge payment methods on the in3win site are only available in the Recharge menu"
];

  const changeText = () => {
    setTimeout(() => {
      let index = Math.floor(Math.random() * texts.length);
      setText(texts[index]);
      setChange(!change);
    }, 7000);
  };

  const handleSpeakText = () => {
    handleSpeak(text);
  };

  useEffect(() => {
    changeText();
  }, [change]);

  const handleUpdateUser = (updatedData) => {
    setUser(updatedData.data ? updatedData.data.data : {});
  };
  const showResult = (data) => {
    setModelData(data);
    setTimeout(() => {
      setModelData({});
    }, 2000);
  };

  const tabs = [
    {
      label: "30s",
      content: (
        <Wingo10
          money={user?.money}
          user_id={user?.uid}
          updateUser={handleUpdateUser}
          showResult={showResult}
        />
      ),
    },
    {
      label: "1Min",
      content: (
        <Wingo1
          money={user?.money}
          user_id={user?.uid}
          updateUser={handleUpdateUser}
          showResult={showResult}
        />
      ),
    },
    {
      label: "3Min",
      content: (
        <Wingo3
          money={user?.money}
          user_id={user?.uid}
          updateUser={handleUpdateUser}
          showResult={showResult}
        />
      ),
    },
    {
      label: "5Min",
      content: (
        <Wingo5
          money={user?.money}
          user_id={user?.uid}
          updateUser={handleUpdateUser}
          showResult={showResult}
        />
      ),
    },
  ];

  const [toast, setToast] = useState({
    isVisible: false,
    message: "",
  });

  const showToast = (message) => {
    setToast({ isVisible: true, message });
    setTimeout(() => {
      setToast({ isVisible: false, message: "" });
    }, 1500); // Hide after 3 seconds
  };
  const handleRefresh = () => {
    showToast(
      `<svg width="25px" height="25px" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--emojione-monotone" preserveAspectRatio="xMidYMid meet" fill="#fff" stroke="#fff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M56 2L18.8 42.909L8 34.729H2L18.8 62L62 2z" fill="#fff"></path></g></svg>Refresh successfully`
    );
  };

  const handleTabClick = (label) => {
    setActiveTab(label);
  };

  useEffect(() => {
    const getUserData = async () => {
      const userData = await getUser();
      if (userData.status === 200) {
        if (userData.data.status === "expired") {
          navigate("/login");
        }
        setUser(userData.data.data);
      } else {
        navigate("/login");
      }
    };
    getUserData();
  }, [toast]);

  return (
    <>
      <Dragon />
      <GameHeader path="/" heading="Notification" />
      <div className="curve-bg"></div>
      <div className="game-content">
        <div className="balance-container">
          <div className="balance-container1">
            <span className="amt">
              {user?.money ? formatMoney(user?.money) : 0.0}
            </span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15px"
              height="15px"
              viewBox="0 0 15 15"
              onClick={handleRefresh}
            >
              <path
                fill="#768096"
                fillRule="evenodd"
                d="M13.293 3L11.146.854l.708-.708l3 3a.5.5 0 0 1 0 .708l-3 3l-.708-.708L13.293 4H3.5A2.5 2.5 0 0 0 1 6.5V8H0V6.5A3.5 3.5 0 0 1 3.5 3zM15 7v1.5a3.5 3.5 0 0 1-3.5 3.5H1.707l2.147 2.146l-.708.708l-3-3a.5.5 0 0 1 0-.707l3-3l.708.707L1.707 11H11.5A2.5 2.5 0 0 0 14 8.5V7z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <div className="balance-container2">
           <img src={walletsvg} style={{width:"21px"}}/>
            <span className="next-text">Wallet balance</span>
          </div>
          <div className="balance-container3">
            <div className="withdraw-btn">
              <Link to={"/wallet/Withdraw"}>Withdraw</Link>
            </div>
            <div className="deposit-btn">
              <Link to={"/wallet/Recharge"}>Deposit</Link>
            </div>
          </div>
        </div>
        <div className="newletter d-flex justify-center align-center mt-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20px"
            height="20px"
            viewBox="0 0 20 20"
            onClick={handleSpeakText}
          >
            <path
              fill="#f95959"
              d="M2 7h4l5-4v14l-5-4H2zm12.69-2.46C14.82 4.59 18 5.92 18 10s-3.18 5.41-3.31 5.46a.49.49 0 0 1-.65-.27c-.11-.26.02-.55.27-.65c.11-.05 2.69-1.15 2.69-4.54c0-3.41-2.66-4.53-2.69-4.54a.493.493 0 0 1-.27-.65c.1-.25.39-.38.65-.27M16 10c0 2.57-2.23 3.43-2.32 3.47q-.09.03-.18.03c-.2 0-.39-.12-.47-.32c-.1-.26.04-.55.29-.65c.07-.02 1.68-.67 1.68-2.53s-1.61-2.51-1.68-2.53a.52.52 0 0 1-.29-.65c.1-.25.39-.39.65-.29c.09.04 2.32.9 2.32 3.47"
            />
          </svg>
          <div className="text">{text}</div>
          <div className="d-flex justify-center align-center btn">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10px"
              height="10px"
              viewBox="0 0 48 48"
            >
              <path
                fill="#fff"
                stroke="#fff"
                strokeLinejoin="round"
                strokeWidth="4"
                d="M24 44c8.235 0 15-6.526 15-14.902c0-2.056-.105-4.26-1.245-7.686c-1.14-3.426-1.369-3.868-2.574-5.984c-.515 4.317-3.27 6.117-3.97 6.655c0-.56-1.666-6.747-4.193-10.45C24.537 8 21.163 5.617 19.185 4c0 3.07-.863 7.634-2.1 9.96c-1.236 2.325-1.468 2.41-3.013 4.14c-1.544 1.73-2.253 2.265-3.545 4.365C9.236 24.565 9 27.362 9 29.418C9 37.794 15.765 44 24 44Z"
              />
            </svg>
            <Link to="/main/Notification">
              <span>Detail</span>
            </Link>
          </div>
        </div>
        <div className="time-tab">
          {tabs.map((tab) => (
            <div
              key={tab.label}
              className={`time-tab-box ${
                activeTab === tab.label ? "active" : ""
              }`}
              onClick={() => handleTabClick(tab.label)}
            >
              <img
                src={
                  activeTab === tab.label ? wingoTabLogoActive : wingoTabLogo
                }
                alt={tab.label}
              />
              <span>Win Go</span>
              <span>{tab.label}</span>
            </div>
          ))}
        </div>
        <div className="wingo-tab-content">
          {tabs.find((tab) => tab.label === activeTab)?.content}
        </div>
      </div>
      <WinStatus datax={modelData} />
      <Toast
        message={toast.message}
        isVisible={toast.isVisible}
        onClose={() => setToast({ isVisible: false, message: "" })}
      />
    </>
  );
};

export default Wingo;
