import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/css/main.css";
import App from "./App";
import ErrorBoundary from './ErrorBoundary';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    
  <ErrorBoundary>
      <App />
    </ErrorBoundary>
    
    
    );
